import $ from "dom7";
import React, {
	useEffect,
	useRef,
	useState,
	useContext,
	useCallback,
	memo,
	useMemo,
} from "react";
import { useSelector } from "react-redux";
import {
	Tab,
	List,
	ListItem,
	Searchbar,
	Subnavbar,
	Preloader,
	f7,
	ListGroup,
	Toolbar,
	Link,
	Tabs,
	useStore,
} from "framework7-react";
import RecentChatItem from "../../../../components/Items/recentChatItem";
import { STATUS_CODE } from "../../../../constants";
import { ENDPOINTS, LISTENERS } from "../../../../constants/socket";
import { SocketContext } from "../../../../socket";
import { v4 as uuidv4 } from "uuid";
import emptyChat from "../../../../assets/images/placeholders/chat.gif";
import "./style.css";
import HomeNavbar from "../../../../components/navs/navbar";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { useTranslation } from "react-i18next";
import {
	convertToMillisecondsPrecision,
	createCancelableFunction,
	debounce,
	isJson,
} from "../../../../utils/functions";
import user from "../../../../assets/images/placeholders/user.png";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useUpdateEffect, useWindowSize } from "react-use";
import notesicon from "../../../../assets/images/icons/notes-icon.svg";
import { onConversationsStatusEventHandler } from "../../../../socket/appEvents";

const Chat = ({ f7router, toggleNewChatState, stateChaged }) => {
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 775, [width]);
	const activeConversation = useStore("activeConversation");
	// const [totalUnreadCount, setTotalUnreadCount] = useState(0);
	const { t } = useTranslation();
	const typingTimer = useRef(null);
	const doneTypingInterval = useRef(700);
	const searchBar = useRef(null);
	const socket = useContext(SocketContext);
	const { isLoading } = useSelector((state) => state.conversationSlice);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [searchTerm, setSearchTerm] = useState("");
	const [activeSearchTab, setActiveSearchTab] = useState(0);
	const [searchChats, setSearchChats] = useState([]);
	const [searchGroups, setSearchGroups] = useState([]);
	const [searchContacts, setSearchContacts] = useState([]);
	const [searchMessages, setSearchMessages] = useState([]);
	const updateIndexLoop = useRef(0);
	const [, forceUpdate] = useState(0);

	const myOwnChatId = loginResponse?.data?.chat_id
		? parseInt(loginResponse.data.chat_id)
		: 0;

	const conversations = useLiveQuery(async () => {
		let data = await db.chats.toArray();
		const myChat = data.find((chat) => chat.chat_id === myOwnChatId);
		data = data.filter((chat) => chat.chat_id !== myOwnChatId);
		data = data
			.sort((a, b) => {
				return (
					convertToMillisecondsPrecision(parseInt(a.unix_time)) -
					convertToMillisecondsPrecision(parseInt(b.unix_time))
				);
			})
			.reverse();

		if (myChat) {
			data.unshift(myChat);
		}
		// const adminIndex = data.findIndex((chat) => chat.chat_id === 11);
		// const todayMidnight = new Date();
		// todayMidnight.setHours(0, 0, 0, 0);
		// if (
		// 	adminIndex > -1 &&
		// 	convertToMillisecondsPrecision(data[adminIndex]?.unix_time) <
		// 		convertToMillisecondsPrecision(todayMidnight.getTime())
		// ) {
		// 	data.splice(adminIndex, 1);
		// }
		return data;
	});

	const _drafts = useLiveQuery(() => db.drafts.toArray());

	const drafts = useMemo(() => _drafts, [_drafts]);

	const _contacts = useLiveQuery(() => db.users.toArray());

	const contacts = useMemo(() => _contacts, [_contacts]);

	useEffect(() => {
		$("#chatTab .searchbar input").on("blur", () => {
			searchBar.current.el.f7Searchbar.query?.trim().length === 0 &&
				searchBar.current.el.f7Searchbar.disable();
		});

		// f7.on("indexChanged", () => {
		// 	//setClickedIndex(1);
		// });

		f7.on("clearChatSearch", () => {
			searchBar.current && searchBar.current.el.f7Searchbar.clear();
			searchBar.current && searchBar.current.el.f7Searchbar.disable();
		});

		return () => {
			$("#chatTab .searchbar input").off("blur");
			// f7.off("indexChanged");
			f7.off("clearChatSearch");
		};
	}, []);

	useEffect(() => {
		if (conversations?.length > 0) {
			const newTotalUnreadCount = conversations.reduce(
				(total, convo) => total + parseInt(convo.un_read_count),
				0
			);
			document.title =
				newTotalUnreadCount > 0
					? `(${newTotalUnreadCount}) KT Messenger`
					: "KT Messenger";
			if (navigator.setAppBadge) {
				newTotalUnreadCount > 0
					? navigator.setAppBadge(newTotalUnreadCount)
					: navigator.clearAppBadge();
			}
		}
		if (conversations?.length > 0 && searchBar.current) {
			searchBar.current.el.classList.add("initialized");
			searchBar.current = f7.searchbar.create({
				el: searchBar.current.el,
				notFoundEl: ".chat-not-found",
				hideOnSearchEl: ".chat-list",
				backdrop: false,
				inner: false,
				disableButton: true,
				customSearch: true,
				on: {
					search: (searchbar, query, previousQuery) => {
						if (query && query.trim().length > 0) {
							typingTimer.current && clearTimeout(typingTimer.current);
							setSearchTerm(query.trim());
							typingTimer.current = setTimeout(() => {
								clearTimeout(typingTimer.current);
								const chats = conversations?.filter((conversation) => {
									return (
										conversation.firstname
											?.toLowerCase()
											.includes(query.toLowerCase()) ||
										conversation.lastname
											?.toLowerCase()
											.includes(query.toLowerCase()) ||
										conversation.nickname
											?.toLowerCase()
											.includes(query.toLowerCase())
									);
								});
								setSearchChats(chats);

								// Modified groups search to include both member matches and direct group name matches
								db.members
									.filter((member) => {
										return (
											member.firstname
												.toLowerCase()
												.includes(query.toLowerCase()) ||
											member.lastname
												.toLowerCase()
												.includes(query.toLowerCase()) ||
											member.nickname
												.toLowerCase()
												.includes(query.toLowerCase()) ||
											member.username
												.toLowerCase()
												.includes(query.toLowerCase())
										);
									})
									.toArray()
									.then(async (members) => {
										const memberGroups = await db.chats
											.filter((chat) => {
												return (
													chat.type === "group" &&
													members.some(
														(member) => member.chat_id === chat.chat_id
													)
												);
											})
											.toArray();

										const directGroups = conversations?.filter((chat) => {
											return (
												chat.type === "group" &&
												(chat.firstname?.toLowerCase().includes(query.toLowerCase()) ||
												chat.lastname?.toLowerCase().includes(query.toLowerCase()) ||
												chat.nickname?.toLowerCase().includes(query.toLowerCase()))
											);
										}) || [];

										const allGroups = [...memberGroups, ...directGroups];
										const uniqueGroups = Array.from(new Map(allGroups.map(group => 
											[group.chat_id, group]
										)).values());
										
										setSearchGroups(uniqueGroups);
									});

								db.users
									.filter((contact) => {
										if (contact.id == loginResponse.data.id) return false;
										return (
											contact.name
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.kalam_name
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.email
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.number
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.phone
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.kalam_number
												?.toLowerCase()
												.includes(query.toLowerCase()) ||
											contact.username
												?.toLowerCase()
												.includes(query.toLowerCase())
										);
									})
									.toArray()
									.then(async (contactsFiltered) => {
										setSearchContacts(contactsFiltered);
									});

								db.messages
									.filter((message) => {
										return (
											(!isJson(message.message) &&
												message.message
													.toLowerCase()
													.includes(query.toLowerCase())) ||
											message.caption
												.toLowerCase()
												.includes(query.toLowerCase())
										);
									})
									.toArray()
									.then((messages) => {
										setSearchMessages(messages);
									}, 50),
									doneTypingInterval.current;
							}, 700);
						} else {
							setSearchTerm("");
							setSearchChats([]);
							setSearchGroups([]);
							setSearchContacts([]);
							setSearchMessages([]);
						}
					},
					clear: () => {
						setSearchTerm("");
						setSearchChats([]);
						setSearchGroups([]);
						setSearchContacts([]);
						setSearchMessages([]);
					},
				},
			});
		}
	}, [conversations]);

	const updateIndex = (chat_id, index, type) => {
		f7.popup.close();
		f7.sheet.close();
		f7.popover.close();
		if (!chat_id || chat_id === "null") return;
		chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
		if (index > -1) {
			if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
				f7.views.main.router.navigate(`/chats/null/single`);
			f7.store.dispatch("setActiveConversation", parseInt(chat_id));
			f7.store.dispatch("setActiveConversationType", type);
		}
	};

	useEffect(() => {
		f7.on(LISTENERS.ALL_MESSAGES_READ, (response) => {
			if (response.chat_id) {
				if (response.user_id == loginResponse.data.id) {
					// seen by me
					db.chats.where({ chat_id: parseInt(response.chat_id) }).modify({
						un_read_count: 0,
					});
				}
			}
		});
		f7.on(LISTENERS.NEW_MESSAGE, (response) => {
			if (response.id && response.sender_id != loginResponse.data.id) {
				if (activeConversation && activeConversation == response.chat_id) {
					// socket.emit(
					// 	ENDPOINTS.MESSAGE_SEEN,
					// 	JSON.stringify({
					// 		message_id: response.id,
					// 		chat_id: response.chat_id,
					// 		sender_id: response.sender_id,
					// 	}),
					// 	(data) => {
					// 		console.log("socket new msg MESSAGE_SEEN ", data);
					// 	}
					// );
				} else {
					socket?.emit(
						ENDPOINTS.MESSAGE_DELIVERED,
						JSON.stringify({
							message_id: response.id,
							chat_id: response.chat_id,
							sender_id: response.sender_id,
						}),
						(data) => {}
					);
				}
			}

			response.chat_id = parseInt(response.chat_id);
			db.chats.get(response.chat_id).then(async (chatObj) => {
				let unReadCount = chatObj.un_read_count;
				if (activeConversation && activeConversation == response.chat_id) {
					unReadCount = 0;
				} else if (response.sender_id != loginResponse.data.id) {
					unReadCount = chatObj.un_read_count + 1;
				}
				let unix_time = response.unix_time
					? parseInt(response.unix_time)
					: new Date().getTime();
				db.chats.where({ chat_id: response.chat_id }).modify({
					un_read_count: unReadCount,
					message: response.message,
					unix_time: unix_time,
				});
			});
		});
		return () => {
			f7.off(LISTENERS.NEW_MESSAGE);
			f7.off(LISTENERS.ALL_MESSAGES_READ);
		};
	}, [activeConversation]);

	const createChat = (contact, navigate = true) => {
		const identifier = uuidv4();
		socket.emit(
			ENDPOINTS.START_CHAT,
			JSON.stringify({
				chat_id: 0,
				receiver_id: contact?.id,
				identifier: identifier,
				group_id: identifier,
				user_id: contact.id,
			}),
			(response) => {
				const addChat = async () => {
					await db.chats.add({
						chat_id: response,
						can_send: true,
						disappearing_duration: null,
						accepted_by: null,
						durition: 0,
						firstname:
							contact?.firstname || contact?.name || contact?.kalam_name,
						hide_profile_picture: contact?.hide_profile_picture || false,
						hide_screenshot: null,
						image: null,
						is_muted: 0,
						is_online: 0,
						is_private_chat: 1,
						is_read: 0,
						last_message_id: null,
						lastname: contact?.lastname || null,
						message: null,
						message_sender_id: null,
						mobile: contact?.mobile || contact?.kalam_number,
						msg_type: null,
						nickname: contact?.nickname || contact?.name || contact?.kalam_name,
						owner_id: loginResponse?.data?.user_id,
						profile_image: contact?.profile_image || null,
						reaction: null,
						sender_id: null,
						type: "single",
						un_read_count: 0,
						unix_time: Date.now() / 1000,
						user_id: contact?.id,
					});
				};
				db.chats
					.where({ chat_id: response })
					.first()
					.then(async (chat) => {
						if (!chat) await addChat();
						if (navigate) {
							if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
								f7.views.main.router.navigate(`/chats/null/single`);
							f7.store.dispatch("setActiveConversation", parseInt(response));
							f7.store.dispatch("setActiveConversationType", "single");
						}
					})
					.catch(async (error) => {
						await addChat();
						if (navigate) {
							if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
								f7.views.main.router.navigate(`/chats/null/single`);
							f7.store.dispatch("setActiveConversation", parseInt(response));
							f7.store.dispatch("setActiveConversationType", "single");
						}
					});
			}
		);
	};

	const startChat = (contact) => {
		if (contact.chat_id) {
			if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
				f7.views.main.router.navigate(`/chats/null/single`);
			f7.store.dispatch("setActiveConversation", parseInt(contact.chat_id));
			f7.store.dispatch("setActiveConversationType", "single");
		} else {
			createChat(contact);
		}
	};

	const highlightText = (text) => {
		if (!searchTerm) return text;
		const parts = text?.split(new RegExp(`(${searchTerm})`, "gi"));

		const highlightedText = parts?.map((part, index) => {
			if (part.toLowerCase() === searchTerm.toLowerCase()) {
				return (
					<span key={index} className="text-kt-blue">
						{part}
					</span>
				);
			}
			return part;
		});

		return highlightedText;
	};

	const RenderSearchChats = memo(({ items, title = "", limit = null }) => {
		return (
			<ListGroup>
				{title && <ListItem title={t(title)} groupTitle />}
				{items?.map((conversation, index) => {
					return limit ? (
						index < limit ? (
							<RecentChatItem
								key={index}
								index={index + 1}
								owner={conversation.message_sender_id === loginResponse.data.id}
								chat={conversation}
								selected={false}
								highlightTitle={true}
								searchTerm={searchTerm}
								updateIndex={updateIndex}
							/>
						) : (
							index === limit && (
								<ListItem
									key={index}
									title={t("View More")}
									className="cursor-pointer search-more-btn"
									tabLink="#searchChatTab"
									onClick={() => setActiveSearchTab(1)}>
									<MaterialIcon
										slot="after"
										icon="chevron_right"
										className="text-[#A0A0A0]"
									/>
								</ListItem>
							)
						)
					) : (
						<RecentChatItem
							key={index}
							index={index + 1}
							owner={conversation.message_sender_id === loginResponse.data.id}
							chat={conversation}
							selected={false}
							highlightTitle={true}
							searchTerm={searchTerm}
							updateIndex={updateIndex}
						/>
					);
				})}
			</ListGroup>
		);
	});

	const RenderSearchGroups = memo(({ items, title = "", limit = null }) => {
		return (
			<ListGroup>
				{title && <ListItem title={t(title)} groupTitle />}
				{items?.map((conversation, index) => {
					return limit ? (
						index < limit ? (
							<RecentChatItem
								key={index + "groupsearch"}
								index={index + 1}
								owner={conversation.message_sender_id === loginResponse.data.id}
								chat={conversation}
								selected={false}
								highlightTitle={true}
								searchTerm={searchTerm}
								updateIndex={updateIndex}
							/>
						) : (
							index === limit && (
								<ListItem
									key={index + "groupsearch"}
									title={t("View More")}
									className="cursor-pointer search-more-btn"
									tabLink="#searchGroupTab"
									onClick={() => setActiveSearchTab(2)}>
									<MaterialIcon
										slot="after"
										icon="chevron_right"
										className="text-[#A0A0A0]"
									/>
								</ListItem>
							)
						)
					) : (
						<RecentChatItem
							key={index + "groupsearch"}
							index={index + 1}
							owner={conversation.message_sender_id === loginResponse.data.id}
							chat={conversation}
							selected={false}
							highlightTitle={true}
							searchTerm={searchTerm}
							updateIndex={updateIndex}
						/>
					);
				})}
			</ListGroup>
		);
	});
	const RenderSearchContacts = memo(({ items, title = "", limit = null }) => {
		return (
			<ListGroup>
				{title && <ListItem title={t(title)} groupTitle />}
				{items?.map((contact, index) => {
					return limit ? (
						index < limit ? (
							<ListItem
								key={index + "contactsearch"}
								onClick={() => startChat(contact)}
								title={highlightText(contact?.name)}
								className="cursor-pointer panel-close"
								subtitle={highlightText(
									contact?.bio ||
										contact?.email ||
										contact?.phone ||
										contact?.username ||
										contact?.kalam_name ||
										contact?.kalam_number
								)}>
								<img
									slot="media"
									src={
										!contact.hide_profile_picture ? contact.profile_image : user
									}
									placeholder={user}
									height={45}
									width={45}
									alt=""
									className="rounded-full align-bottom"
									wrapperclassname="rounded-full align-bottom"
								/>
							</ListItem>
						) : (
							index === limit && (
								<ListItem
									key={index + "contactsearch"}
									title={t("View More")}
									className="cursor-pointer search-more-btn"
									tabLink="#searchContactTab"
									onClick={() => setActiveSearchTab(3)}>
									<MaterialIcon
										slot="after"
										icon="chevron_right"
										className="text-[#A0A0A0]"
									/>
								</ListItem>
							)
						)
					) : (
						<ListItem
							key={index + "contactsearch"}
							onClick={() => startChat(contact)}
							title={highlightText(contact?.name)}
							className="cursor-pointer panel-close"
							subtitle={contact?.bio}>
							<img
								slot="media"
								src={
									!contact.hide_profile_picture ? contact.profile_image : user
								}
								placeholder={user}
								height={45}
								width={45}
								alt=""
								className="rounded-full align-bottom"
								wrapperclassname="rounded-full align-bottom"
							/>
						</ListItem>
					);
				})}
			</ListGroup>
		);
	});
	const RenderSearchMessages = memo(({ items, title = "", limit = null }) => {
		return (
			<ListGroup>
				{title && <ListItem title={t(title)} groupTitle />}
				{items?.map((message, index) => {
					const chat = conversations.find(
						(convo) => convo.chat_id == message.chat_id
					);
					const title = chat?.nickname
						? chat?.nickname
						: chat?.firstname + " " + chat?.lastname;

					const subtitle_prefix =
						message.chat_type === "single"
							? ""
							: loginResponse.data.id == chat.message_sender_id
							? "You: "
							: chat.firstname + ": ";
					const subtitle =
						message.type === "text"
							? message.message.replace(
									/@\[([^\]]+)\]\((\d+)\)/g,
									(match, name, id) => `@${name}`
							  )
							: message.caption.replace(
									/@\[([^\]]+)\]\((\d+)\)/g,
									(match, name, id) => `@${name}`
							  );
					return limit ? (
						index < limit ? (
							<ListItem
								key={index + "messageSearch"}
								onClick={async () => {
									if (
										activeConversation ===
										message.chat_id
									) {
										f7.emit("searchMessage", message.id);
									} else {
										f7router.navigate(
											`/chats/${message.chat_id}/${chat.type}?message_id=${message.id}`
										);
									}
								}}
								title={highlightText(title)}
								className="cursor-pointer panel-close"
								subtitle={highlightText(subtitle_prefix + subtitle)}></ListItem>
						) : (
							index === limit && (
								<ListItem
									key={index + "messageSearch"}
									title={t("View More")}
									className="cursor-pointer search-more-btn"
									tabLink="#searchMessageTab"
									onClick={() => setActiveSearchTab(4)}>
									<MaterialIcon
										slot="after"
										icon="chevron_right"
										className="text-[#A0A0A0]"
									/>
								</ListItem>
							)
						)
					) : (
						<ListItem
							key={index + "messageSearch"}
							onClick={async () => {
								if (
									activeConversation === message.chat_id
								) {
									f7.emit("searchMessage", message.id);
								} else {
									f7router.navigate(
										`/chats/${message.chat_id}/${chat.type}?message_id=${message.id}`
									);
								}
							}}
							title={highlightText(title)}
							className="cursor-pointer panel-close"
							subtitle={highlightText(subtitle_prefix + subtitle)}></ListItem>
					);
				})}
			</ListGroup>
		);
	});

	return (
		<Tab id="chatTab" tabActive onTabShow={() => stateChaged("chats")}>
			<HomeNavbar toggleNewChatState={toggleNewChatState} />
			<Subnavbar>
				<Searchbar
					ref={searchBar}
					init={false}
					inline
					inner={false}
					placeholder={t("search")}
					className="w-full"
				/>
			</Subnavbar>

			{searchTerm?.length > 0 && (
				<Toolbar tabbar scrollable top>
					<Link tabLink="#searchAllTab" tabLinkActive>
						{t("All")}
					</Link>
					<Link tabLink="#searchChatTab">{t("Chats")}</Link>{" "}
					<Link tabLink="#searchGroupTab">{t("Groups")}</Link>
					<Link tabLink="#searchContactTab">{t("Contacts")}</Link>
					<Link tabLink="#searchMessageTab">{t("Messages")}</Link>
				</Toolbar>
			)}

			<div className="page-content pr-0">
				{conversations?.length < 2 && (
					<List
						menuList
						mediaList
						className={`chat-list mt-0 bg-white ${
							searchTerm?.length > 0 ? "hidden" : ""
						}`}>
						<ul>
							<RecentChatItem
								index={0}
								owner={true}
								chat={{
									nickname: t("My Notes"),
									profile_image: notesicon,
									is_read: 2,
									un_read_count: 0,
									is_muted: 0,
								}}
								selected={myOwnChatId === activeConversation}
								draft={
									myOwnChatId !== activeConversation
										? drafts?.find((draft) => draft.chat_id === myOwnChatId)
										: false
								}
								updateIndex={updateIndex}
							/>
						</ul>
					</List>
				)}
				{isLoading && conversations?.length < 1 && (
					<div className="flex flex-col justify-center items-center h-4/5">
						<Preloader color="#0e99fe" />
					</div>
				)}

				{conversations?.length >= 1 ? (
					<List
						menuList
						mediaList
						// virtualList
						// virtualListParams={{
						// 	items: conversations,
						// 	searchAll,
						// 	renderExternal,
						// 	height: "auto",
						// }}
						className={`chat-list mt-0 bg-white ${
							searchTerm?.length > 0 ? "hidden" : ""
						}`}>
						<ul>
							{conversations.map((conversation, index) => {
								return (
									<RecentChatItem
										key={index}
										index={index + 1}
										owner={
											conversation.message_sender_id == loginResponse.data.id
										}
										chat={
											conversation.chat_id === myOwnChatId
												? {
														...conversation,
														nickname: t("My Notes"),
														profile_image: notesicon,
														is_read: 2,
														un_read_count: 0,
														is_muted: 0,
												  }
												: conversation
										}
										selected={conversation.chat_id == activeConversation}
										draft={
											conversation.chat_id !== activeConversation
												? drafts?.find(
														(draft) => draft.chat_id === conversation.chat_id
												  )
												: false
										}
										updateIndex={updateIndex}
									/>
								);
							})}
						</ul>
					</List>
				) : (
					<div className="flex flex-col justify-center items-center h-4/5">
						<span className="text-xl text-secondary font-bold mb-[20px]">
							{t("No conversations here yet...")}
						</span>
						<span className="text-lg text-center self-stretch text-body">
							{t("Start messaging by tapping the new chat button on the top")}
						</span>
						<img src={emptyChat} className="w-[150px] h-[150px]" />
					</div>
				)}
				{searchTerm?.length > 0 && (
					<Tabs>
						<Tab id="searchAllTab" tabActive={activeSearchTab === 0}>
							<List
								menuList
								mediaList
								dividersMd
								className="search-list searchbar-found mt-0 bg-white pb-[var(--f7-toolbar-height)]">
								{searchChats?.length > 0 && (
									<RenderSearchChats
										items={searchChats}
										title={t("Chats")}
										limit={3}
									/>
								)}
								{searchGroups?.length > 0 && (
									<RenderSearchGroups
										items={searchGroups}
										title={t("Groups")}
										limit={3}
									/>
								)}
								{searchContacts?.length > 0 && (
									<RenderSearchContacts
										items={searchContacts}
										title={t("Contacts")}
										limit={3}
									/>
								)}
								{searchMessages?.length > 0 && (
									<RenderSearchMessages
										items={searchMessages}
										title={t("Messages")}
										limit={3}
									/>
								)}
								{searchChats?.length < 1 &&
									searchGroups?.length < 1 &&
									searchContacts?.length < 1 &&
									searchMessages?.length < 1 && (
										<ListGroup>
											<ListItem title={t("Nothing found")} />{" "}
										</ListGroup>
									)}
							</List>
						</Tab>
						<Tab id="searchChatTab" tabActive={activeSearchTab === 1}>
							<List
								menuList
								mediaList
								dividersMd
								className="search-list searchbar-found mt-0 bg-white pb-[var(--f7-toolbar-height)]">
								{searchChats?.length > 0 ? (
									<RenderSearchChats items={searchChats} />
								) : (
									<ListGroup>
										<ListItem title={t("No chat found")} />{" "}
									</ListGroup>
								)}
							</List>
						</Tab>
						<Tab id="searchGroupTab" tabActive={activeSearchTab === 2}>
							<List
								menuList
								mediaList
								dividersMd
								className="search-list searchbar-found mt-0 bg-white pb-[var(--f7-toolbar-height)]">
								{searchGroups?.length > 0 ? (
									<RenderSearchGroups items={searchGroups} />
								) : (
									<ListGroup>
										<ListItem title={t("No group found")} />{" "}
									</ListGroup>
								)}
							</List>
						</Tab>
						<Tab id="searchContactTab" tabActive={activeSearchTab === 3}>
							<List
								menuList
								mediaList
								dividersMd
								className="search-list searchbar-found mt-0 bg-white pb-[var(--f7-toolbar-height)]">
								{searchContacts?.length > 0 ? (
									<RenderSearchContacts items={searchContacts} />
								) : (
									<ListGroup>
										<ListItem title={t("No contact found")} />{" "}
									</ListGroup>
								)}
							</List>
						</Tab>
						<Tab id="searchMessageTab" tabActive={activeSearchTab === 4}>
							<List
								menuList
								mediaList
								dividersMd
								className="search-list searchbar-found mt-0 bg-white pb-[var(--f7-toolbar-height)]">
								{searchMessages?.length > 0 ? (
									<RenderSearchMessages items={searchMessages} />
								) : (
									<ListGroup>
										<ListItem title={t("No message found")} />{" "}
									</ListGroup>
								)}
							</List>
						</Tab>
					</Tabs>
				)}
			</div>
		</Tab>
	);
};

export default Chat;
