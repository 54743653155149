import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Block,
	Link,
	NavLeft,
	NavTitle,
	NavRight,
	Navbar,
	Page,
	Toggle,
	Preloader,
	f7,
	Button,
	Popup,
	useStore,
} from "framework7-react";
import {
	Call,
	ClipboardExport,
	Flag,
	Message,
	MessageRemove,
	Send,
	Sms,
	User,
	UserAdd,
	UserMinus,
	UserRemove,
	UserSquare,
	Video,
} from "iconsax-react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useSelector, useDispatch } from "react-redux";
import user from "../../../../assets/images/placeholders/user.png";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { useUpdateEffect, useWindowSize } from "react-use";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { useTranslation } from "react-i18next";
import "./style.css";
import { ReportUserService } from "../../../../redux/features/userSlice/reportUser";
import TimerIcon from "../../../../assets/images/icons/timer_icon.svg?react";
import { userProfileService } from "../../../../redux/features/userSlice/profile";
import { v4 as uuidv4 } from "uuid";
import { deleteContactsService } from "../../../../redux/features/userSlice/deleteContact";
import { STATUS_CODE } from "../../../../constants";
import ChatMediaWidget from "../../../../components/Items/ChatMediaWidget";
import MuteChatWidget from "../../../../components/Items/MuteChatWidget";
import CallSettingWidget from "../../../../components/Items/CallSettingWidget";
import ForwardSettingWIdget from "../../../../components/Items/ForwardSettingWidget";
import DisappearWidget from "../../../../components/Items/DisappearWidget";
import UserForwordSettingWidget from "../../../../components/Items/UserForwordSettingWidget";

const ContactInfo = ({ chat_id, user_id, userMode }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	user_id = user_id !== "posh_ai" ? parseInt(user_id) : user_id;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 775, [width]);
	const [viewerOpen, setViewerOpen] = useState(false);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { userProfileResponse } = useSelector((state) => state.profileSlice);
	const { deleteContactsResponse, isLoading } = useSelector(
		(state) => state.deleteContactSlice
	);
	const socket = useContext(SocketContext);
	const profileData = useLiveQuery(
		() => db.users.where({ id: user_id }).first(),
		[user_id, userProfileResponse],
		{}
	);
	const [blockChecked, setBlockChecked] = useState(false);
	const [callChecked, setCallChecked] = useState(false);
	const [switchToggle, setSwitchToggle] = useState(false);

	useUpdateEffect(() => {
		setViewerOpen(false);
		setBlockChecked(false);
		setCallChecked(false);
		setSwitchToggle(false);
	}, [chat_id]);
	const mediaCount = !userMode
		? useLiveQuery(
				async () => {
					if (!chat_id || chat_id === "null" || isNaN(chat_id))
						return 0;
					let count = await db.messages
						.where({ chat_id: chat_id, type: "image" })
						.count();
					return count;
				},
				[chat_id],
				0
		  )
		: null;

	const chats = !userMode
		? useLiveQuery(
				async () => {
					if (!chat_id || chat_id === "null" || isNaN(chat_id))
						return null;
					await db.chats.get(chat_id);
				},
				[chat_id],
				null
		  )
		: null;

	// const user = user_id
	// 	? useLiveQuery(() => db.users.where({ id: user_id }).toArray())
	// 	: [];

	useEffect(() => {
		setBlockChecked(profileData?.blocked);
		setCallChecked(profileData?.hide_phone); //change later
	}, [profileData]);

	const block = useCallback(() => {
		const name =
			profileData?.nickname ||
			profileData?.name ||
			profileData?.kalam_name ||
			profileData?.firstname + " " + profileData?.lastname;
		f7.dialog
			.create({
				title: `${t("block")} ${name}`,
				text: t(
					"Blocked contacts cannot call or send you messages. This contact will not be notified."
				),
				buttons: [
					{
						text: t("Cancel"),
						color: "secondary",
						close: true,
						onClick: function () {
							setBlockChecked(false);
						},
					},
					{
						text: t("block"),
						color: "red",
						onClick: function (dialog, e) {
							f7.preloader.show();
							socket.emit(
								ENDPOINTS.BLOCK_CONTACT,
								JSON.stringify({
									user_id: loginResponse.data.id,
									block_id: profileData.id,
								}),
								(response) => {
									if (response.chat_id) {
										db.chats
											.where({
												chat_id: parseInt(
													response.chat_id
												),
											})
											.modify({ is_blocked: 1 })
											.then((id) => {})
											.catch((ex) => {});
									}

									db.users
										.where({ id: profileData.id })
										.modify({ blocked: 1 })
										.then(() => {
											f7.preloader.hide();
											dialog.close();
										});
								}
							);
						},
					},
				],
			})
			.open();
	}, [profileData]);

	const unblock = useCallback(() => {
		const name =
			profileData?.nickname ||
			profileData?.name ||
			profileData?.kalam_name ||
			profileData?.firstname + " " + profileData?.lastname;
		f7.dialog
			.create({
				title: `${t("Unblock")} ${name}`,
				text: t(
					"Unblocked contacts can call or send you messages. This contact will not be notified."
				),
				buttons: [
					{
						text: t("Cancel"),
						color: "secondary",
						close: true,
						onClick: function () {
							setBlockChecked(true);
						},
					},
					{
						text: t("Unblock"),
						color: "blue",
						onClick: function (dialog, e) {
							f7.preloader.show();
							socket.emit(
								ENDPOINTS.UNBLOCK_CONTACT,
								JSON.stringify({
									user_id: loginResponse.data.id,
									block_id: profileData.id,
								}),
								(response) => {
									if (response.chat_id) {
										db.chats
											.where({
												chat_id: parseInt(
													response.chat_id
												),
											})
											.modify({ is_blocked: 0 })
											.then((id) => {})
											.catch((ex) => {});
									}
									db.users
										.where({ id: profileData.id })
										.modify({ blocked: 0 })
										.then(() => {
											f7.preloader.hide();
											dialog.close();
										});
								}
							);
						},
					},
				],
			})
			.open();
	}, [profileData]);

	useUpdateEffect(() => {
		if (switchToggle) {
			if (blockChecked) {
				block();
			} else {
				unblock();
			}
			setSwitchToggle(false);
		}
	}, [switchToggle, blockChecked, profileData]);

	useEffect(() => {
		f7?.on("unblock", () => {
			unblock();
			setSwitchToggle(false);
			setBlockChecked(false);
		});

		return () => {
			f7?.off("unblock");
		};
	}, [profileData]);

	const sendRequest = () => {
		socket.emit(
			ENDPOINTS.SEND_REQUEST,
			JSON.stringify({
				receiver_id: user_id,
				user_id: loginResponse.data.id,
			}),
			(response) => {
				dispatch(
					userProfileService({
						user_id: user_id,
					})
				);
				f7.toast
					.create({
						text: t(response),
					})
					.open();
			}
		);
	};

	const cancelRequest = (id) => {
		socket.emit(
			ENDPOINTS.CANCEL_FRIEND_REQUEST,
			JSON.stringify({
				request_id: user_id,
			}),
			(response) => {
				if (response?.status) {
					dispatch(
						userProfileService({
							user_id: user_id,
						})
					);
					db.users.where({ id: user_id }).modify({ request_id: 0 });
				}
			}
		);
	};

	useUpdateEffect(() => {
		if (deleteContactsResponse.code === STATUS_CODE.SUCCESS) {
			dispatch(
				userProfileService({
					user_id: user_id,
				})
			);
		} else {
			f7.toast
				.create({
					text: t(deleteContactsResponse.message),
				})
				.open();
		}
	}, [deleteContactsResponse]);

	const removeContact = () => {
		dispatch(deleteContactsService({ user_id }));
	};

	const startChat = useCallback(async () => {
		if (profileData.chat_id) {
			if (
				isMobile &&
				f7.router.currentRoute.path !== "/chats/null/single"
			)
				f7.views.main.router.navigate(`/chats/null/single`);
			f7.store.dispatch(
				"setActiveConversation",
				parseInt(profileData.chat_id)
			);
			f7.store.dispatch("setActiveConversationType", "single");
		} else {
			const identifier = uuidv4();
			socket.emit(
				ENDPOINTS.START_CHAT,
				JSON.stringify({
					chat_id: 0,
					receiver_id: profileData?.id,
					identifier: identifier,
					group_id: identifier,
					user_id: loginResponse?.data?.user_id,
				}),
				(response) => {
					const addChat = async () => {
						await db.chats.add({
							chat_id: response,
							can_send: true,
							disappearing_duration: null,
							accepted_by: null,
							durition: 0,
							firstname:
								profileData?.firstname ||
								profileData?.name ||
								profileData?.kalam_name,
							hide_profile_picture:
								profileData?.hide_profile_picture || false,
							hide_screenshot: null,
							image: null,
							is_muted: 0,
							is_online: 0,
							is_private_chat: 1,
							is_read: 0,
							last_message_id: null,
							lastname: profileData?.lastname || null,
							message: null,
							message_sender_id: null,
							mobile:
								profileData?.mobile ||
								profileData?.kalam_number,
							msg_type: null,
							nickname:
								profileData?.nickname ||
								profileData?.name ||
								profileData?.kalam_name,
							owner_id: loginResponse?.data?.user_id,
							profile_image: profileData?.profile_image || null,
							reaction: null,
							sender_id: null,
							type: "single",
							un_read_count: 0,
							unix_time: Date.now() / 1000,
							user_id: profileData?.id,
						});
					};
					db.chats
						.where({ chat_id: response })
						.first()
						.then(async (chat) => {
							if (!chat) await addChat();
							if (
								isMobile &&
								f7.router.currentRoute.path !==
									"/chats/null/single"
							)
								f7.views.main.router.navigate(
									`/chats/null/single`
								);
							f7.store.dispatch(
								"setActiveConversation",
								parseInt(response)
							);
							f7.store.dispatch(
								"setActiveConversationType",
								"single"
							);
						})
						.catch(async (error) => {
							await addChat();
							if (
								isMobile &&
								f7.router.currentRoute.path !==
									"/chats/null/single"
							)
								f7.views.main.router.navigate(
									`/chats/null/single`
								);
							f7.store.dispatch(
								"setActiveConversation",
								parseInt(response)
							);
							f7.store.dispatch(
								"setActiveConversationType",
								"single"
							);
						});
				}
			);
		}
	}, [profileData]);

	const callStart = (mode, chatType) => {
		f7.store.dispatch("setCallChatId", parseInt(profileData.chat_id));
		f7.store.dispatch("setCallType", mode);
		f7.store.dispatch("setCallDirection", "outgoing");
		f7.store.dispatch("setCallChatType", chatType);
		f7.store.dispatch("setShowCallScreen", true);
	};

	return (
		<Page>
			<Navbar>
				<NavLeft>
					<Link panelClose>
						<MaterialIcon size={48} icon="close" />
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Contact information")}
				</NavTitle>
			</Navbar>
			{!profileData && (
				<div className="flex flex-col justify-center items-center h-100 w-100">
					<Preloader />
				</div>
			)}
			{profileData && (
				<>
					<div className="flex flex-col items-center justify-center gap-[25px] bg-[#F6F6F6] cursor-pointer">
						<LazyLoaderWraper
							src={profileData?.profile_image || user}
							placeholder={user}
							height={150}
							width={150}
							alt=""
							className="rounded-full object-cover"
							wrapperclassname="rounded-full"
							onClick={() => {
								setViewerOpen(true);
							}}
						/>
						<div className="gap-[5px] flex flex-col items-around justify-center">
							<h1 className="text-[34px] text-[--f7-heading-color] leading-[46px] font-bold text-center">
								{profileData?.name || profileData?.kalam_name}
							</h1>
							<p className="text-[18px] text-[--f7-heading-color] font-normal text-center break-words profile-bio">
								{profileData?.bio}
							</p>
						</div>
					</div>
					<div
						className={`flex items-center ${
							profileData.contact_id === 0
								? "justify-center"
								: "justify-between"
						} py-5 px-10 gap-2.5 bg-[#F6F6F6]`}>
						{profileData.contact_id !== 0 && (
							<>
								<Button
									className="w-[80px] rounded-[5px] flex flex-col items-center justify-center gap-2.5"
									onClick={startChat}>
									<Message
										size="35"
										color="#0e99fe"
										variant="Bold"
									/>
									<span className="text-black text-sm">
										{t("Message")}
									</span>
								</Button>

								<Button
									className="w-[80px] rounded-[5px] flex flex-col items-center justify-center gap-2.5"
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("audio", "single");
									}}>
									<Call
										size="35"
										color="#0e99fe"
										variant="Bold"
									/>
									<span className="text-black text-sm">
										{t("Audio")}
									</span>
								</Button>
								<Button
									className="w-[80px] rounded-[5px] flex flex-col items-center justify-center gap-2.5"
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("video", "single");
									}}>
									<Video
										size="35"
										color="#0e99fe"
										variant="Bold"
									/>
									<span className="text-black text-sm">
										{t("Videos")}
									</span>
								</Button>
							</>
						)}
						<Button
							className="w-[80px] rounded-[5px] flex flex-col items-center justify-center gap-2.5"
							onClick={() =>
								profileData.request_id === 0 &&
								profileData.contact_id === 0
									? sendRequest()
									: profileData.contact_id !== 0
									? removeContact()
									: cancelRequest()
							}
							disabled={isLoading}>
							{isLoading ? (
								<Preloader size={35} />
							) : (
								<>
									{profileData.request_id === 0 &&
									profileData.contact_id === 0 ? (
										<UserAdd
											size="35"
											color="#0e99fe"
											variant="Bold"
										/>
									) : (
										<UserMinus
											size="35"
											color="#e05047"
											variant="Bold"
										/>
									)}
									<span className="text-black text-sm">
										{t(
											profileData.request_id === 0 &&
												profileData.contact_id === 0
												? "Connect"
												: "Remove"
										)}
									</span>
								</>
							)}
						</Button>
					</div>
				</>
			)}
			<Popup
				tabletFullscreen
				className="dp-viewer bg-transparent"
				opened={viewerOpen}
				onPopupClose={() => setViewerOpen(false)}>
				<Page className="bg-black/60">
					<Navbar transparent className="mt-[30px]">
						<NavLeft transparent className="ms-[30px]">
							<LazyLoaderWraper
								src={profileData?.profile_image || user}
								placeholder={user}
								height={45}
								width={45}
								alt=""
								className="rounded-full align-bottom object-cover"
								wrapperclassname="rounded-full align-bottom"
							/>
							<div className="flex flex-col justify-center items-start ml-[10px]">
								<span className="dark-theme text-body text-2xl font-medium text-ellipsis max-w-[335px]">
									{profileData?.name ||
										profileData?.kalam_name}
								</span>
							</div>
						</NavLeft>
						<NavRight className="me-[30px]">
							{/* <Link
								href={!edit ? user?.profile_image : userEdit?.profile_image}
								external
								className="h-[40px] w-[40px]"
								download
								target="_blank"
							>
								<Import size={24} color="#A6A9AF" />
							</Link> */}
							<Button onClick={() => setViewerOpen(false)}>
								<MaterialIcon
									icon="close"
									className="text-2xl cursor-pointer"
									size={24}
									weight={70}
									height={70}
									color="#A6A9AF"
								/>
							</Button>
						</NavRight>
					</Navbar>
					<div className="h-full flex flex-col justify-center items-center">
						<LazyLoaderWraper
							src={profileData?.profile_image || user}
							placeholder={user}
							height={640}
							width={640}
							className="object-cover"
							alt=""
						/>
					</div>
				</Page>
			</Popup>
			{profileData && (
				<Block>
					<div>
						{profileData?.profile_id && (
							<div
								className="flex gap-[10px] items-center mt-6"
								onClick={() => {
									navigator.clipboard.writeText(
										profileData?.profile_id
									);
									f7.toast
										.create({
											text: t("Copied"),
											position: "center",
											closeTimeout: 2000,
										})
										.open();
								}}>
								<UserSquare color={"#0e99fe"} variant="Bold" />
								<p className="text-[16px] text-[--f7-heading-color] font-[400] max-w-[250px] truncate">
									{profileData?.profile_id}
								</p>
								<MaterialIcon
									icon="content_copy"
									color="#b7b7b7"
									className="cursor-pointer text-[16px]"
								/>
							</div>
						)}
						{profileData?.email && (
							<div className="flex gap-[10px] items-center mt-6">
								<Sms color={"#0e99fe"} variant="Bold" />
								<p className="text-[16px] text-[--f7-heading-color] font-[400]">
									{!profileData.hide_email
										? profileData?.email
										: t(`{{name}} has hidden their email`, {
												name:
													profileData?.name ||
													profileData?.kalam_name,
										  })}{" "}
								</p>
							</div>
						)}
						<div className="flex gap-[10px] items-center mt-6">
							<Call color={"#0e99fe"} variant="Bold" />
							<p className="text-[16px] text-[--f7-heading-color] font-[400]">
								{!profileData.hide_phone
									? profileData?.phone ||
									  profileData.kalam_number
									: t(
											`{{name}} has hidden their phone number`,
											{
												name:
													profileData?.name ||
													profileData?.kalam_name,
											}
									  )}{" "}
							</p>
						</div>
					</div>
					<div className="mt-[35px]">
						{!userMode && (
							<ChatMediaWidget
								chat_id={chat_id}
								onClick={(id) => f7.emit("setSelectChat", id)}
							/>
						)}
						{!userMode && (
							<p className="text-[14px] mt-[35px] text-[--f7-chat-heading-color] font-[800]">
								{t("CHAT SETTINGS")}
							</p>
						)}
						<div>
							{!userMode && (
								<MuteChatWidget
									chat_id={chat_id}
									user_id={user_id}
									checked={profileData?.is_mute || false}
								/>
							)}
							{!userMode && (
								<DisappearWidget
									chat_id={chat_id}
									user_id={user_id}
									checked={
										chats?.disappearing_duration !== null &&
										parseInt(
											chats?.disappearing_duration
										) !== 0
											? true
											: false
									}
									duration={parseInt(
										chats?.disappearing_duration || 0
									)}
								/>
							)}
							{/* {!userMode && (
								<CallSettingWidget
									chat_id={chat_id}
									user_id={user_id}
									checked={profileData?.is_mute || false}
								/>
							)} */}
							{!userMode && (
								<UserForwordSettingWidget
									chat_id={chat_id}
									checked={chats?.allow_forwarding || false}
									subtitle="Enabling this option will allow group members to forward your messages sent within the group chat to other users/groups"
								/>
							)}
							<div
								className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[40px]">
								<div className="flex items-start gap-[20px]">
									<div className="min-w-[24px] min-h-[24px] ">
										<UserRemove
											color={"#E05047"}
											variant="Bold"
											icon="mail"
										/>
									</div>
									<div className="flex-1">
										<div className="flex justify-between w-full">
											<p className="text-[16px] font-[400] text-[--kt-danger]">
												{t("block")}
											</p>
											<Toggle
												checked={blockChecked}
												onToggleChange={(e) => {
													setSwitchToggle(true);
													setBlockChecked(!e);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							{!userMode && (
								<div
									className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
									<div className="flex items-start gap-[20px]">
										<div className="min-w-[24px] min-h-[24px] ">
											<MessageRemove
												color={"#E05047"}
												variant="Bold"
												icon="mail"
											/>
										</div>
										<div className="flex-1">
											<div className="flex justify-between w-full">
												<p
													className="text-[16px] font-[400] text-[--kt-danger] cursor-pointer"
													onClick={() => {
														f7.dialog
															.create({
																title: t(
																	"Clear this chat?"
																),
																text: t(
																	"Messages will only be removed from this device and other devices you have logged into"
																),
																buttons: [
																	{
																		text: t(
																			"Clear"
																		),
																		cssClass:
																			"custom-clear-button",
																		onClick:
																			function (
																				dialog
																			) {
																				const chatId =
																					parseInt(
																						profileData.chat_id
																					);
																				socket.emit(
																					ENDPOINTS.CLEAR_MESSAGES,
																					JSON.stringify(
																						{
																							chat_id:
																								chatId,
																						}
																					),
																					(
																						response
																					) => {
																						dialog.close();
																					}
																				);
																				db.messages
																					.where(
																						{
																							chat_id:
																								chatId,
																						}
																					)
																					.delete()
																					.then(
																						function (
																							deleteCount
																						) {}
																					);
																			},
																	},
																	{
																		text: t(
																			"Cancel"
																		),
																		cssClass:
																			"custom-Cancel-button",
																		onClick:
																			function () {
																				// Handle checkout logic
																				// This is where you can add code for the checkout button
																			},
																	},
																],
															})
															.open();
													}}>
													{t("Clear Chat")}
												</p>
											</div>
										</div>
									</div>
								</div>
							)}
							<div
								className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
								<div className="flex items-start gap-[20px]">
									<div className="min-w-[24px] min-h-[24px] ">
										<Flag
											color={"#E05047"}
											variant="Bold"
											icon="mail"
										/>
									</div>
									<div className="flex-1">
										<div className="flex justify-between w-full">
											<p
												className="text-[16px] font-[400] text-[--kt-danger] cursor-pointer"
												onClick={() => {
													const name =
														profileData?.nickname ||
														profileData?.name ||
														profileData?.kalam_name ||
														profileData?.firstname +
															" " +
															profileData?.lastname;
													f7.dialog
														.create({
															title: `${t(
																"Report"
															)} ${name} ?`,
															text: `${t(
																"Last few messages will be forwarded to KT Messenger,"
															)}`,
															content: `
							  <div class="text-block">${t("The contact will not be notified.")}</div>
          <div class="list Blockclear">
		  
            <ul>
              <li>
                <label class="item-checkbox item-content list_update">
                  <input type="checkbox" name="BlockContact" value="BlockContact">
                  <i class="icon icon-checkbox"></i>
                  <div class="item-inner">
                    <div class=""> ${t("Block contact")}</div>
                  </div>
                </label>
              </li>
			  <li>
                <label class="item-checkbox item-content list_update">
                  <input type="checkbox" name="ClearChat" value="ClearChat">
                  <i class="icon icon-checkbox"></i>
                  <div class="item-inner">
                    <div class=""> ${t("Clear Chat")}</div>
                  </div>
                </label>
              </li>
            </ul>
			 <li><input type="text" name="reason" placeholder="Enter Report Reason" class="styled-input hindden"></li>
          </div>
           `,
															buttons: [
																{
																	text: t(
																		"Report"
																	),
																	cssClass:
																		"custom-clear-button",
																	onClick:
																		function (
																			dialog
																		) {
																			const chatId =
																				parseInt(
																					profileData.chat_id
																				);
																			const user_id =
																				parseInt(
																					profileData.id
																				);
																			const reason =
																				document.querySelector(
																					'input[name="reason"]'
																				).value;

																			// Your code for reporting user via API call goes here
																			dispatch(
																				ReportUserService(
																					{
																						user_id,
																						reason,
																					}
																				)
																			);

																			const blockContactChecked =
																				document.querySelector(
																					'input[name="BlockContact"]:checked'
																				);
																			if (
																				blockContactChecked
																			) {
																				socket.emit(
																					ENDPOINTS.BLOCK_CONTACT,
																					JSON.stringify(
																						{
																							user_id:
																								loginResponse
																									.data
																									.id,
																							block_id:
																								profileData.id,
																						}
																					),
																					(
																						response
																					) => {
																						dialog.close();
																					}
																				);
																			}
																			const is_Checked =
																				blockContactChecked
																					? true
																					: false;

																			// Handle clear chat logic
																			const clearChatChecked =
																				document.querySelector(
																					'input[name="ClearChat"]:checked'
																				);
																			if (
																				clearChatChecked
																			) {
																				socket.emit(
																					ENDPOINTS.CLEAR_MESSAGES,
																					JSON.stringify(
																						{
																							chat_id:
																								chatId,
																						}
																					),
																					(
																						response
																					) => {
																						dialog.close();
																					}
																				);
																			}
																			// db.messages
																			//   .where({ chat_id: chatId })
																			//   .delete()
																			//   .then(function (deleteCount) {
																			//     console.log(
																			//       "Deleted " + deleteCount + " objects"
																			//     );
																			//   });

																			// Handle clear logic
																			const isChecked =
																				clearChatChecked
																					? true
																					: false;

																			dialog.close();
																		},
																},
																{
																	text: t(
																		"Cancel"
																	),
																	cssClass:
																		"custom-Cancel-button",
																	onClick:
																		function () {
																			// Handle cancel logic
																		},
																},
															],
														})
														.open();
												}}>
												{t("Report this user")}
											</p>
										</div>
									</div>
								</div>
							</div>
							{/* {!userMode && (
                <div
                  className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]"
                >
                  <div className="flex items-start gap-[20px]">
                    <div className="min-w-[24px] min-h-[24px] ">
                      <Bag color={"#E05047"} variant="Bold" icon="mail" />
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between w-full">
                        <p
                          className="text-[16px] font-[400] text-[--kt-danger]"
                          onClick={() => {
                            const name =
                              profileData?.nickname ||
                              profileData?.name ||
                              profileData?.kalam_name ||
                              profileData?.firstname +
                                " " +
                                profileData?.lastname;
                            f7.dialog
                              .create({
                                title: `${t("Delete this user")} ${name} ?`,
                                text: `${t(
                                  "Messages and all other things will b removed in chat nothing remining in your chat"
                                )}
														
																		<div class="list">
																			<ul>
																				<li>
																					<label class="item-checkbox item-content">
																						<input type="checkbox" name="Delete-user" value="Delete-user">
																						<i class="icon icon-checkbox"></i>
																						<div class="item-inner">
																							<div class=""> ${t(
                                                "Also delete media received  in this chat from the device gallery"
                                              )}</div>
																						</div>
																					</label>
																				</li>
																			</ul>
																		</div>
																	`,
                                buttons: [
                                  {
                                    text: t("Delete"),
                                    cssClass: "custom-clear-button",
                                    onClick: function (dialog) {
                                      const chatId = parseInt(
                                        profileData.chat_id
                                      );
                                      socket.emit(
                                        ENDPOINTS.CLEAR_MESSAGES,
                                        JSON.stringify({
                                          chat_id: chatId,
                                        }),
                                        (response) => {
                                          console.log(
                                            "Response From CLEAR_MESSAGES Socket : ",
                                            response
                                          );
                                          dialog.close();
                                        }
                                      );
                                      db.messages
                                        .where({
                                          chat_id: chatId,
                                        })
                                        .delete()
                                        .then(function (deleteCount) {
                                          console.log(
                                            "Deleted " +
                                              deleteCount +
                                              " objects"
                                          );
                                        });

                                      // Handle clear logic
                                      const isChecked = document.querySelector(
                                        'input[name="Delete-user"]'
                                      ).checked;
                                      console.log(
                                        "Delete-user button clicked, Checkbox is checked: ",
                                        isChecked
                                      );
                                    },
                                  },
                                  {
                                    text: t("Cancel"),
                                    cssClass: "custom-Cancel-button",
                                    onClick: function () {
                                      // Handle checkout logic
                                      // This is where you can add code for the checkout button
                                    },
                                  },
                                ],
                              })
                              .open();
                          }}
                        >
                          {t("Delete this user")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
						</div>
					</div>
				</Block>
			)}
		</Page>
	);
};

export default ContactInfo;
