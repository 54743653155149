import React, { useState, useEffect } from "react";
import {
  Popup,
  Page,
  Navbar,
  List,
  ListItem,
  Button,
  Searchbar,
} from "framework7-react";
import userimage from "../../assets/images/placeholders/user.png";
import LazyLoaderWraper from "../misc/lazyloaderWraper";
import {AddSquare} from "iconsax-react";
import { db } from "../../js/db";

const AddContactsPopup = ({ userId, onAddContacts,inCallContacts }) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      try {
        const allContacts = await db.users
          .orderBy("name")
          .filter(
            (user) =>
              user.contact_id !== 0 &&
              user.blocked === 0 &&
              user.id !== userId
          )
          .toArray();
        setContacts(allContacts);
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (popupOpened) {
      fetchContacts();
    }
  }, [popupOpened, userId]);
  const safeInCallContacts = Array.isArray(inCallContacts) ? inCallContacts : [];
  const filteredContacts = contacts
    .filter((contact) => !safeInCallContacts.includes(contact.id))
    .filter((contact) =>
      contact.name.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  

  const handleContactSelection = (contactId) => {
    setSelectedContacts((prev) =>
      prev.includes(contactId)
        ? prev.filter((id) => id !== contactId)
        : [...prev, contactId]
    );
  };

  const handleAddContacts = () => {
    onAddContacts(selectedContacts);
    setSelectedContacts([]);
    setPopupOpened(false);
  };

  return (
    <>
      <Button fill onClick={() => setPopupOpened(true)} aria-label="Add Contacts" className="h-[40px] p-5 mr-[10px]" >
        <AddSquare size={20} color="#ffffff" />
      </Button>

      <Popup opened={popupOpened} onPopupClosed={() => setPopupOpened(false)}>
        <Page>
          <Navbar title="Add Participants">
            <Button slot="right" onClick={() => setPopupOpened(false)}>
              Close
            </Button>
          </Navbar>

          <Searchbar
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Contacts"
            clearButton
          />

          {loading ? (
            <p>Loading contacts...</p>
          ) : (
            <div className="popup-content-wrapper" style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 50px)', paddingBottom: '60px' }}>
              {/* Contact List */}
              <div style={{ flex: 1, overflowY: 'auto' }}>
                <List>
                  {filteredContacts.map((contact) => (
                    <ListItem
                      key={contact.id}
                      checkbox
                      checked={selectedContacts.includes(contact.id)}
                      onChange={() => handleContactSelection(contact.id)}
                      title={contact.name}
                    >
                      <LazyLoaderWraper
                        slot="media"
                        src={
                          !contact.hide_profile_picture
                            ? contact.profile_image
                            : userimage
                        }
                        height={45}
						width={45}
						alt=""
						className="rounded-full object-cover"
                      />
                    </ListItem>
                  ))}
                </List>
              </div>

              {/* Add Button fixed at the bottom */}
              <div style={{ position: 'absolute', bottom: '10px', left: '0', right: '0', padding: '10px' }}>
                <Button
                  fill
                  onClick={handleAddContacts}
                  disabled={selectedContacts.length === 0}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        </Page>
      </Popup>
    </>
  );
};

export default AddContactsPopup;
