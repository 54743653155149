import React, { useContext, useEffect, useState } from "react";
import {
	Block,
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Toggle,
	Preloader,
	f7,
	NavRight,
	List,
	ListItem,
	Button,
	Popup,
	useStore,
} from "framework7-react";
import {
	Bag,
	Calendar,
	Edit,
	Logout,
	SecurityUser,
	Send,
	Message,
	Profile,
	ProfileAdd,
} from "iconsax-react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useDispatch, useSelector } from "react-redux";
import user from "../../../../assets/images/placeholders/user.png";
import groupimage from "../../../../assets/images/placeholders/group.png";
import admin from "../../../../assets/images/icons/admin-user-blue.svg";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { useUpdateEffect } from "react-use";
import { getGroupDetailService } from "../../../../redux/features/chatSlice/groupDetail";
import { db } from "../../../../js/db";
import { useLiveQuery } from "dexie-react-hooks";
import "./style.css";
import { v4 as uuidv4 } from "uuid";
import { editGroupService } from "../../../../redux/features/chatSlice/editGroup";
import { useTranslation } from "react-i18next";
import { userProfileService } from "../../../../redux/features/userSlice/profile";
import { conversationService } from "../../../../redux/features/chatSlice/conversation";
import TimerIcon from "../../../../assets/images/icons/timer_icon.svg?react";
import ChatMediaWidget from "../../../../components/Items/ChatMediaWidget";
import MuteChatWidget from "../../../../components/Items/MuteChatWidget";
import ForwardSettingWIdget from "../../../../components/Items/ForwardSettingWidget";
import DisappearWidget from "../../../../components/Items/DisappearWidget";
import UserForwordSettingWidget from "../../../../components/Items/UserForwordSettingWidget";

const GroupInfo = ({
	chat_id,
	switchMode,
	openMembersGroup,
	openAddMembers,
	f7router,
}) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const { t, i18n } = useTranslation();
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const dispatch = useDispatch();
	const { getGroupDetailResponse, isLoading } = useSelector(
		(state) => state.groupDetailSlice
	);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { uploadFileResponse } = useSelector(
		(state) => state.uploadFileSlice
	);
	const socket = useContext(SocketContext);
	const [switchToggle, setSwitchToggle] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [popupData, setPopupData] = useState(null);
	const [isadmin, setIsAdmin] = useState(false);
	const [selectedUser, setSelectedUser] = useState(0);
	const [disapperChecked, setdisapperChecked] = useState(false);

	useUpdateEffect(() => {
		setSwitchToggle(false);
		setIsPopupOpen(false);
		setPopupData(null);
		setIsAdmin(false);
		setSelectedUser(0);
	}, [chat_id]);
	// const [vlData, setVlData] = useState({
	// 	items: [],
	// });
	const mediaCount = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || isNaN(chat_id)) return 0;
			let count = await db.messages
				.where({ chat_id: chat_id, type: "image" })
				.count();
			return count;
		},
		[chat_id],
		0
	);
	const contacts = useLiveQuery(() =>
		db.users
			.orderBy("name")
			.filter((user) => user.blocked === 0)
			.toArray()
	);

	const members = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || isNaN(chat_id)) return [];
			return await db.members.where({ chat_id: chat_id }).toArray();
		},
		[chat_id],
		[]
	);
	const chats = useLiveQuery(
		async () => {
			if (!chat_id || chat_id === "null" || isNaN(chat_id)) return null;
			return await db.chats.get(chat_id);
		},
		[chat_id],
		null
	);

	const userProfile = useLiveQuery(
		() => db.users.where({ id: parseInt(selectedUser) }).first(),
		// f7.emit("selectChat", selectedUser)
		[selectedUser],
		null
	);
	useEffect(() => {
		var isadmin = false;
		if (members?.length > 0) {
			members.forEach((item) => {
				if (loginResponse.data.id == item.id && item.is_admin) {
					isadmin = true;
				}
			});
		}
		setIsAdmin(isadmin);
	}, [members]);

	useEffect(() => {
		dispatch(getGroupDetailService({ chat_id }));
	}, [chat_id]);

	const dateString = chats?.created_at;
	const dateObject = new Date(dateString);
	const options = { year: "numeric", month: "long", day: "numeric" };
	const createdAt = dateObject.toLocaleDateString("en-US", options);

	// const renderExternal = (vl, newData) => {
	// 	setVlData({ ...newData });
	// };

	// const searchAll = (query, searchItems) => {
	// 	const found = [];
	// 	for (let i = 0; i < searchItems.length; i += 1) {
	// 		if (
	// 			searchItems[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
	// 			query.trim() === ""
	// 		)
	// 			found.push(i);
	// 	}
	// 	return found;
	// };

	const openPopup = (userData) => {
		setPopupData(userData);
		setIsPopupOpen(true);
	};
	const closePopup = () => {
		setIsPopupOpen(false);
	};

	const deleteGroupAdmin = () => {
		// Assuming popupData contains the user's information, including user_id
		const userId = popupData?.user_id;
		const chatsid = popupData?.chats_id;

		// Emit a socket event to the server to make the user a group admin

		socket.emit(
			ENDPOINTS.DELETE_GROUP_ADMINS,
			JSON.stringify({
				chat_id: chatsid,
				user_id: userId,
			}),
			(response) => {
				db.members
					.where({ chat_id: parseInt(chatsid), id: parseInt(userId) })
					.modify({
						is_admin: 0,
					});
			}
		);
		f7.popup.close();
	};
	const makeGroupAdmin = () => {
		// Assuming popupData contains the user's information, including user_id
		const userId = popupData?.user_id;

		const chatsid = popupData?.chats_id;

		// Emit a socket event to the server to make the user a group admin
		socket.emit(
			ENDPOINTS.ADD_GROUP_ADMIN,
			JSON.stringify({
				chat_id: chatsid,
				user_id: userId,
			}),
			(response) => {
				db.members
					.where({ chat_id: parseInt(chatsid), id: parseInt(userId) })
					.modify({
						is_admin: 1,
					});
			}
		);
		f7.popup.close();
	};
	const removedFromGroup = () => {
		dispatch(
			editGroupService({
				chat_id: popupData?.chats_id, // Add the chat_id if needed
				// Group Name: popupData?.name,
				// add_group_members:[],
				remove_group_members: [popupData?.user_id],
			})
		);
		db.members
			.where({
				chat_id: parseInt(popupData?.chats_id),
				id: parseInt(popupData?.user_id),
			})
			.delete();
		f7.popup.close();
	};
	const viewPorfile = () => {
		f7.popup.close();
		f7.panel.close();
		f7.store.dispatch("setSelectedUserId", popupData?.user_id);
	};

	useEffect(() => {
		f7?.on("openMemberPopup", openMemberPopup);

		return () => {
			f7?.off("openMemberPopup", openMemberPopup);
		};
	});

	const openMemberPopup = (contact) => {
		dispatch(
			userProfileService({
				user_id: contact?.id,
			})
		);
		setSelectedUser(contact?.id);
		openPopup({
			image: contact?.profile_image || user,
			name: contact.firstname ? contact.firstname : contact.username,
			user_id: contact?.id,
			chats_id: chat_id,
			is_admin: contact?.is_admin,
			contact: contact,
		});
	};
	const sendRequest = () => {
		socket.emit(
			ENDPOINTS.SEND_REQUEST,
			JSON.stringify({
				receiver_id: contacts.request_id,
				user_id: contacts.chat_id,
			}),
			(response) => {}
		);
	};

	return chats ? (
		<Page>
			<Navbar>
				<NavLeft>
					<Link panelClose>
						<MaterialIcon size={48} icon="close" />
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Group information")}
				</NavTitle>

				{isadmin && (
					<NavRight className="pr-[20px]">
						<Button onClick={switchMode}>
							<Edit
								className="cursor-pointer"
								color="#5D6980"
								size={"25"}
							/>
						</Button>
					</NavRight>
				)}
			</Navbar>
			<Block className="flex pb-[25px] bg-[--f7-navbar-bg-color] my-0 pt-2 flex-col w-full items-center">
				<LazyLoaderWraper
					src={
						isadmin || !chats?.hide_profile_picture
							? chats?.profile_image ||
							  chats?.group_image ||
							  groupimage
							: groupimage
					}
					placeholder={
						isadmin || !chats?.hide_profile_picture
							? chats?.profile_image ||
							  chats?.group_image ||
							  groupimage
							: groupimage
					}
					height={150}
					width={150}
					alt=""
					className="rounded-full align-bottom"
					wrapperclassname="rounded-full align-bottom"
				/>
				<p className="text-[28px] text-[--f7-heading-color] font-[700] pt-[20px] whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">
					{chats?.group_name}
				</p>
			</Block>
			<Block>
				<div className="mt-[-20px]">
					<p className="text-[14px] text-[--f7-chat-heading-color] font-[800]">
						{t("Group information")}
					</p>
					<div className="flex gap-[10px] items-center mt-6">
						<Calendar
							size={"25"}
							color={"#0e99fe"}
							variant="Bold"
							icon="mail"
						/>
						<p className="text-[16px] text-[--f7-heading-color] font-[400]">
							{t("Created on")} {createdAt}
						</p>
					</div>
					<div className="flex gap-[10px] items-center mt-6">
						<SecurityUser
							size={"25"}
							color={"#0e99fe"}
							variant="Bold"
							icon="mail"
						/>
						<p className="text-[16px] text-[--f7-heading-color] font-[400]">
							{t("Created by")} {chats?.creator_name}
						</p>
					</div>
				</div>
				<div className="mt-[35px]">
					<ChatMediaWidget
						chat_id={chat_id}
						onClick={(id) => f7.emit("setSelectChat", id)}
					/>
					<p className="text-[14px] mt-[35px] text-[--f7-chat-heading-color] font-[800]">
						{t("GROUP SETTINGS")}
					</p>
					<div>
						<MuteChatWidget
							chat_id={chat_id}
							mute={chats?.is_mute || false}
						/>

						<DisappearWidget
							chat_id={chat_id}
							checked={
								chats?.disappearing_duration !== null &&
								parseInt(chats?.disappearing_duration) !== 0
									? true
									: false
							}
							duration={parseInt(
								chats?.disappearing_duration || 0
							)}
						/>

						{isadmin && (
							<ForwardSettingWIdget
								chat_id={chat_id}
								checked={chats?.allow_forwarding || false}
								subtitle="Enabling this option will allow group members to forward messages sent within the group chat to other users/groups"
							/>
						)}
						<UserForwordSettingWidget
							chat_id={chat_id}
							checked={chats?.allow_forwarding || false}
							subtitle="Enabling this option will allow group members to forward your messages sent within the group chat to other users/groups"
						/>
						<div>
							<div className="flex items-end justify-between pr-[20px]">
								<p className="text-[14px] mt-[52px] text-[--f7-chat-heading-color] font-[800]">
									{t("GROUP MEMBERS")}
									<span className="pl-[6px] pr-[6px]">
										{chats?.users_results?.length}
									</span>
								</p>
								<MaterialIcon
									size={14}
									icon="search"
									fill
									color="#0E99FE"
								/>
							</div>

							{isadmin && (
								<div className="flex items-end justify-between pr-[20px]">
									<List>
										<ListItem
											title={t("Add Members")}
											className="cursor-pointer"
											onClick={openAddMembers}>
											<div
												slot="media"
												className="flex justify-center items-center w-[45px] h-[45px] rounded-full bg-Light-theme">
												<ProfileAdd
													size={"25"}
													color={"#0183e2"}
													variant="Bold"
													icon="ProfileAdd"
												/>
											</div>
										</ListItem>
										{/* <ListItem title={t("Invite via Link")}>
											<div
												slot="media"
												className="flex justify-center items-center w-[45px] h-[45px] rounded-full bg-Light-theme">
												<Link1 size="24" color="#0183e2" />
											</div>
										</ListItem> */}
									</List>
								</div>
							)}
							<div>
								{isLoading ? (
									<div className="flex flex-col justify-center items-center h-100 w-100">
										<Preloader />
									</div>
								) : (
									<div>
										{members?.length > 0 ? (
											<List
												mediaList
												strongIos
												// virtualList
												// virtualListParams={{
												// 	items: members,
												// 	searchAll,
												// 	renderExternal,
												// 	createUl: false,
												// 	height: "auto",
												// }}
												className="search-list group-contact-list mt-0 searchbar-found">
												<ul className="h-full">
													{members?.map(
														(contact, index) => (
															<ListItem
																onClick={() =>
																	openMemberPopup(
																		contact
																	)
																}
																key={index}
																className="cursor-pointer">
																<LazyLoaderWraper
																	slot="media"
																	src={
																		contact?.profile_image ||
																		user
																	}
																	placeholder={
																		user
																	}
																	height={55}
																	width={55}
																	alt=""
																	className="rounded-full align-bottom"
																	wrapperclassname="rounded-full align-bottom"
																/>

																<div className="flex flex-col h-full justify-center">
																	{
																		<div className="flex justify-between">
																			<p className="text-[16px] font-[700] text-[--f7-heading-color] overflow-hidden text-ellipsis whitespace-nowrap">
																				{contact.firstname
																					? contact.firstname
																					: contact.username}
																			</p>
																			{contact.is_admin ===
																				1 && (
																				<div
																					className="text-[12px] font-[600]
																px-2 rounded-[5px] bg-[--f7-md-primary-light] flex items-center
																text-[--f7-md-primary] whitespace-nowrap">
																					<p>
																						{t(
																							"Group Admin"
																						)}
																					</p>
																				</div>
																			)}
																		</div>
																	}
																	{/* <p className="text-[14px] font-[400] text-[--f7-heading-color]">
																{t('Hey There!')}
																</p> */}
																</div>
															</ListItem>
														)
													)}
												</ul>
												<Button
													onClick={openMembersGroup}>
													<p className="text-[14px] mt-[12px] text-[--f7-md-primary] cursor-pointer text-center font-[800]">
														{t("VIEW ALL MEMBERS")}
													</p>
												</Button>
											</List>
										) : (
											<List
												strongIos
												outlineIos
												dividersIos
												className="searchbar-not-found">
												<ListItem title="No contacts found" />
											</List>
										)}
									</div>
								)}
								{isPopupOpen && (
									<Popup
										opened={isPopupOpen}
										animate
										className={`group-edit-popup${
											popupData?.is_admin === 1
												? " admin-popup"
												: ""
										}`}
										onPopupClosed={() =>
											setIsPopupOpen(false)
										}>
										<div className="popup-content p-[30px] gap-[20px]">
											<Button
												className="absolute right-0 top-0 cursor-pointer"
												popupClose>
												<MaterialIcon
													size={13}
													icon="close"
													color="#E3E3E3"
												/>
											</Button>
											<Block className="flex pb-[25px] my-0 pt-2 flex-col w-full items-center">
												<LazyLoaderWraper
													src={
														popupData?.image || user
													}
													placeholder={user}
													height={160}
													width={160}
													alt=""
													className="rounded-full align-bottom"
													wrapperclassname="rounded-full align-bottom"
												/>
												<p className="text-[34px] text-[--f7-heading-color] font-[700] pt-[20px]  overflow-hidden text-ellipsis whitespace-nowrap max-w-full max-h-[85px] ">
													{popupData?.name}
												</p>
												{/* <p className="text-[16px] text-[black] font-[500]">firelordsynder</p> */}
											</Block>
											<Block>
												<>
													{userProfile &&
													userProfile?.chat_id &&
													userProfile?.chat_id !==
														loginResponse.data
															.chat_id ? (
														<Link
															popupClose
															className="flex gap-[10px] items-center justify-start mt-6 ms-[25px]"
															href={`/chats/${userProfile?.chat_id}/single`}>
															<Message
																size={"25"}
																color={
																	"#0e99fe"
																}
																variant="Bold"
																icon="Message"
															/>

															<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0] ">
																{t("Message")}
															</p>
														</Link>
													) : userProfile &&
													  userProfile?.id !==
															loginResponse.data
																.id ? (
														<Button
															popupClose
															className="flex h-[25px] p-0 gap-[10px] items-center justify-start mt-6 ms-[25px]"
															onClick={() => {
																const identifier =
																	uuidv4();
																socket.emit(
																	ENDPOINTS.START_CHAT,
																	JSON.stringify(
																		{
																			chat_id: 0,
																			receiver_id:
																				userProfile?.id,
																			identifier:
																				identifier,
																			group_id:
																				identifier,
																			user_id:
																				loginResponse
																					?.data
																					?.user_id,
																		}
																	),
																	(
																		response
																	) => {
																		db.users.update(
																			userProfile?.id,
																			{
																				chat_id:
																					response,
																			}
																		);
																		dispatch(
																			conversationService(
																				{}
																			)
																		);
																		f7.views.main.router.navigate(
																			`/chats/${response}/single`
																		);
																	}
																);
															}}>
															<Message
																size={"25"}
																color={
																	"#0e99fe"
																}
																variant="Bold"
																icon="Message"
															/>
															<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
																{t("Message")}
															</p>
														</Button>
													) : null}

													{userProfile?.id !==
														loginResponse.data
															.id && (
														<Link
															popupClose
															panelClose
															className="flex gap-[10px] items-center justify-start mt-6 ms-[25px] cursor-pointer"
															onClick={
																viewPorfile
															}>
															<Profile
																size={"25"}
																color={
																	"#0e99fe"
																}
																icon="Profile"
																variant="Bold"
															/>

															<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
																{t(
																	"View profile"
																)}
															</p>
														</Link>
													)}
												</>
												{popupData?.is_admin === 0 &&
													loginResponse?.data.id !==
														popupData?.user_id &&
													isadmin && (
														<>
															<div
																className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
																onClick={
																	makeGroupAdmin
																}>
																<img
																	src={admin}
																	size={"25"}
																	color={
																		"#0e99fe"
																	}
																	variant="Bold"
																	icon="admin"
																/>

																<p className="text-[16px] text-[--f7-heading-color] font-[600] mb-[0]">
																	{t(
																		"Make group admin"
																	)}
																</p>
															</div>
															<div
																className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
																onClick={
																	removedFromGroup
																}>
																<ProfileAdd
																	size={"25"}
																	color={
																		"#E05047"
																	}
																	variant="Bold"
																	icon="ProfileAdd"
																/>

																<p className="text-[16px] text-[#E05047] font-[600] mb-[0]">
																	{t(
																		"Remove from group"
																	)}
																</p>
															</div>
														</>
													)}
												{popupData?.is_admin === 1 &&
													isadmin && (
														<>
															<div
																className="flex gap-[10px] items-center mt-6 ms-[25px] cursor-pointer"
																onClick={
																	deleteGroupAdmin
																}>
																<ProfileAdd
																	size={"25"}
																	color={
																		"#E05047"
																	}
																	variant="Bold"
																	icon="ProfileAdd"
																/>

																<p className="text-[16px] text-[#E05047] font-[600] mb-[0]">
																	{t(
																		"Admin remove"
																	)}
																</p>
															</div>
														</>
													)}
											</Block>
										</div>
									</Popup>
								)}
							</div>
						</div>
						<div
							className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
							<div className="flex items-start gap-[20px]">
								<div className="min-w-[24px] min-h-[24px] ">
									<Logout color={"#E05047"} icon="mail" />
								</div>
								<div className="flex-1">
									<div className="flex justify-between w-full">
										<p
											className="text-[16px] font-[400] text-[--kt-danger] cursor-pointer"
											onClick={() => {
												f7.dialog
													.create({
														title: t(
															"Leave this group chat?"
														),
														text: `
            <p class="leave-group" style="
                font-size: 16px;
                line-height: 21.05px;
                font-weight: 400;
                padding-bottom: 10px;
            ">${t(
				"You are about to leave this group. You won’t be able to send or receive messages from this group chat if you leave."
			)}</p>

            <div class="list">
                <p style="
                    font-size: 16px;
                    line-height: 21.05px;
                    font-weight: 400;
                    padding-bottom: 15px;
                "> ${t("Are you sure you want to leave this group chat?")}</p>
                
            </div>
        `,
														buttons: [
															{
																text: t(
																	"Leave & Delete"
																),
																cssClass:
																	"custom-clear-button",
																onClick: async (
																	dialog
																) => {
																	// Check if the "delete-group" checkbox is checked
																	/*const deleteGroupCheckbox =
																		document.querySelector(
																			'input[name="delete-group"]'
																		);
																	const deleteGroup =
																		deleteGroupCheckbox?.checked;
																	// Check if the "leave-group" checkbox is checked
																	const leaveGroupCheckbox =
																		document.querySelector(
																			'input[name="leave-group"]'
																		);
																	const leaveGroup =
																		leaveGroupCheckbox?.checked;*/

																	dialog.close();
																	f7.panel.close();
																	f7.store.dispatch(
																		"setActiveConversation",
																		"null"
																	);
																	f7.store.dispatch(
																		"setActiveConversationType",
																		"single"
																	);
																	f7.store.dispatch(
																		"setActiveMessage",
																		null
																	);
																	f7.dialog.preloader(
																		t(
																			"Leaving group chat"
																		)
																	);

																	await db.messages
																		.where({
																			chat_id:
																				chat_id,
																		})
																		.delete();
																	await db.chats.delete(
																		chat_id
																	);
																	db.members
																		.where({
																			chat_id:
																				chat_id,
																			id: parseInt(
																				loginResponse
																					.data
																					.id
																			),
																		})
																		.delete();

																	socket.emit(
																		ENDPOINTS.LEAVE_GROUP,
																		JSON.stringify(
																			{
																				chat_id:
																					chat_id,
																			}
																		),
																		(
																			response
																		) => {
																			f7.dialog.close();
																			f7.panel.close();
																		}
																	);
																},
															},
															{
																text: t(
																	"Cancel"
																),
																cssClass:
																	"custom-Cancel-button",
																onClick:
																	function () {
																		// Handle cancel logic
																		// This is where you can add code for the cancel button
																	},
															},
														],
													})
													.open();
											}}>
											{t("Leave Group")}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
							<div className="flex items-start gap-[20px]">
								<div className="min-w-[24px] min-h-[24px] ">
									<Bag
										color={"#E05047"}
										variant="Bold"
										icon="mail"
									/>
								</div>
								<div className="flex-1">
									<div className="flex justify-between w-full">
										<p
											className="text-[16px] font-[400] text-[--kt-danger] cursor-pointer"
											onClick={() => {
												f7.dialog
													.create({
														title: t(
															"Delete this group chat?"
														),
														text: `<p class="leave-group" style="
																	font-size: 16px;
																	line-height: 21.05px;
																	font-weight: 400;
																	padding-bottom: 10px;
																">${t(
																	"You are about to delete this group. You won’t be able to send or receive messages from this group chat if you delete."
																)}</p>
																	
																		<div class="list">
																		    <p style="
																			font-size: 16px;
																			line-height: 21.05px;
																			font-weight: 400;
																			padding-bottom: 15px;
																		"> ${t("Are you sure you want to leave this group chat?")}</p>
																		</div>
																	`,
														buttons: [
															{
																text: t(
																	"Delete"
																),
																cssClass:
																	"custom-clear-button",
																onClick: async (
																	dialog
																) => {
																	dialog.close();
																	f7.panel.close();
																	f7.store.dispatch(
																		"setActiveConversation",
																		"null"
																	);
																	f7.store.dispatch(
																		"setActiveConversationType",
																		"single"
																	);
																	f7.dialog.preloader(
																		t(
																			"Deleting group chat"
																		)
																	);
																	socket.emit(
																		ENDPOINTS.DELETE_CHATS,
																		JSON.stringify(
																			{
																				chat_ids:
																					chat_id,
																			}
																		),
																		async (
																			response
																		) => {
																			await db.messages
																				.where(
																					{
																						chat_id:
																							chat_id,
																					}
																				)
																				.delete();

																			await db.chats.delete(
																				chat_id
																			);

																			f7.dialog.close();
																			f7.panel.close();
																		}
																	);
																},
															},
															{
																text: t(
																	"Cancel"
																),
																cssClass:
																	"custom-Cancel-button",
																onClick:
																	function () {
																		// Handle cancel logic
																		// This is where you can add code for the cancel button
																	},
															},
														],
													})
													.open();
											}}>
											{t("Delete Group")}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Block>
		</Page>
	) : (
		<Page>
			<div className="flex flex-col justify-center items-center h-100 w-100">
				<Preloader />
			</div>
		</Page>
	);
};

export default GroupInfo;
