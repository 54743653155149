import { f7, Toggle, useStore } from "framework7-react";
import React, { useContext, useState } from "react";
import TimerIcon from "../../../assets/images/icons/timer_icon.svg?react";
import { SocketContext } from "../../../socket";
import { ENDPOINTS } from "../../../constants/socket";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
import { db } from "../../../js/db";

const DisappearWidget = ({
	chat_id,
	user_id = null,
	checked = false,
	duration = 0,
}) => {
	const socket = useContext(SocketContext);
	const { t } = useTranslation();
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	checked = checked ? true : false;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const [disapperChecked, setDisappearChecked] = useState(false);

	useUpdateEffect(() => {
		setDisappearChecked(checked);
	}, [checked]);

	const setDisappear = () => {
		f7.dialog
			.create({
				title: `${t("Disappearing messages")}`,
				text: `
                        <p class="disappeaar_messges" style="
                            font-size: 16px;
                            line-height: 21.05px;
                            font-weight: 400;
                            color: #0e99fe;
                        ">${t("Make messages in this chat disappear")}</p>

                        <div class="list">
                            <p style="
                                font-size: 16px;
                                line-height: 21.05px;
                                font-weight: 400;
                                padding-bottom: 15px;
                            "> ${t(
															"Automatically delete new messages sent in this chat after a certain period of time. You can also set a default self-destruct timer for all chats in Settings."
														)}</p>
                            <ul>
                                <li>
                                    <label class="item-radio item-content disappeaar_messges">
                               <input type="radio" name="disappeaar_messges" value="1440" ${
																	duration && parseInt(duration) === 1440
																		? "checked"
																		: ""
																}>
                                        <i class="icon icon-radio radio-icon"></i>
                                        <div class="item-inner">
                                            <div class="item-title">${t(
																							"24 Hours"
																						)}</div>
                                        </div>
                                    </label>
                                </li>

                                <li>
                                    <label class="item-radio item-content disappeaar_messges">
                            <input type="radio" name="disappeaar_messges" value="10080"  ${
															duration && parseInt(duration) === 10080
																? "checked"
																: ""
														}> 
                             <i class="icon icon-radio radio-icon"></i>
                                        <div class="item-inner">
                                            <div class="">${t("7 Days")}</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <label class="item-radio item-content disappeaar_messges">
         <input type="radio" name="disappeaar_messges" value="129600"  ${
						duration && parseInt(duration) === 129600 ? "checked" : ""
					}>                                       
         <i class="icon icon-radio radio-icon"></i>
                                        <div class="item-inner">
                                            <div class="item-title">${t(
																							"3 Months"
																						)}</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <label class="item-radio item-content disappeaar_messges">
 <input type="radio" name="disappeaar_messges" value="0"  ${
		!duration ? "checked" : ""
 }>                                        <i class="icon icon-radio radio-icon"></i>
                                        <div class="item-inner">
                                            <div class="item-title">${t(
																							"Off"
																						)}</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    `,
				buttons: [
					{
						text: t("Cancel"),
						color: "secondary",
						close: true,
						onClick: function () {
							setDisappearChecked(checked);
						},
					},
					{
						text: t("Set disappearing timer"),
						color: "blue",
						onClick: function () {
							const selectedValue = document
								.querySelector('input[name="disappeaar_messges"]:checked')
								.value.toString();
							socket.emit(
								ENDPOINTS.TOGGLE_DISAPPEARING_MESSAGES,
								JSON.stringify({
									chat_id: chat_id,
									duration: selectedValue,
									value: parseInt(selectedValue) > 0 ? 1 : 0,
								}),
								(response) => {
									setDisappearChecked(parseInt(selectedValue) > 0);
									db.chats.where({ chat_id: chat_id }).modify((value, ref) => {
										ref.value = {
											...value,
											disappearing_duration:
												parseInt(selectedValue) > 0
													? parseInt(selectedValue)
													: null,
										};
									});
								}
							);
						},
					},
				],
			})
			.open();
	};

	return (
		<div
			className="px-[30px] py-[15px] w-full
                                            rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
			<div className="flex items-start gap-[10px]">
				<div className="min-w-[24px] min-h-[24px] ">
					<TimerIcon
						stroke="#0e99fe"
						fill="#0e99fe"
						height={24}
						width={24}
						className="inline align-bottom mr-1"
					/>
				</div>
				<div className="flex-1">
					<div className="flex justify-between w-full">
						<p className="text-[16px] font-[400] text-[--f7-heading-color]">
							{t("Disappearing messagess")}
						</p>
						<Toggle
							checked={disapperChecked}
							onToggleChange={(e) => {
								setDisappearChecked(!e);
								setDisappear(!e);
							}}
						/>
					</div>
					<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
						{t(
							"Automatically delete new messages sent in this chat after a certain period of time. You can also set a default self-destruct timer for all chats in Settings."
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default DisappearWidget;
