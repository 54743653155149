import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Page,
	Button,
	Link,
	List,
	ListInput,
	ListItem,
	f7,
	Preloader,
	PageContent,
	Popup,
} from "framework7-react";
import {
	signupService,
	loginwithPoshService,
	socialLoginService,
} from "../../redux/features/authSlice/login";
import { useUpdateEffect } from "react-use";
import { STATUS_CODE } from "../../constants";
import AuthNabvar from "../../components/navs/authNavbar";
import PhoneInput from "../../components/inputs/phone-input";
import { Eye, EyeSlash, Lock, Profile } from "iconsax-react";
import QR from "../../assets/images/stock/laptop.png";
import LazyLoaderWraper from "../../components/misc/lazyloaderWraper";
import { useTranslation } from "react-i18next";
import Googlelogin from "../../components/AuthButtons/googleLogIn";
import AppleLoginButton from "../../components/AuthButtons/AppleLoginButton";
import Posh from "../../assets/images/icons/Posh.svg";
import MaterialIcon from "../../components/misc/materialIcon";
import ExternalPagePopup from "../../components/misc/externalPagePopup";
import analytics from "../../js/firebase/analytics";
import { logEvent } from "@firebase/analytics";

const SignupPage = ({ f7router, props }) => {
	const deviceInfo = f7.device;
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const { loginResponse, isLoading } = useSelector(
		(state) => state.loginSlice
	);
	const [phoneNumber, setPhoneNumber] = useState({
		phone: "",
		country: "",
		countryCode: "",
		validationError: false,
	});
	const [passwordType, setPasswordType] = useState("password");
	const [isValidName, setIsvalidName] = useState(true);
	const [display_name, setDisplay_Name] = useState("");
	const [validpassword, setValidPassword] = useState(true);
	const [password, setPaassword] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [usernameworldnoor, setUsernameworldnoor] = useState("");
	const [passwordvalue, setPasswordValue] = useState("password");
	const [passwordworldnoor, setPasswordworldnoor] = useState("");
	const [privacyOpened, setPrivacyOpened] = useState(false);
	const [termsOpened, setTermsOpened] = useState(false);

	const [formData, setFormData] = useState({
		display_name: "",
		country: "PK",
		country_code: "+92",
		password: "",
		phone: "",
		username: "",
		signup_type: "phone",
		fcm_token: "",
		device_type: "",
		version_code: "",
		os_name: deviceInfo.os,
		os_version: deviceInfo.osVersion,
		device_manufaturer: "",
		device_model: "",
	});

	const signUp = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (phoneNumber) {
			formData.phone = phoneNumber.phone;
			formData.country = phoneNumber.country;
			formData.country_code = phoneNumber.countryCode;
			formData.username = phoneNumber.countryCode + formData.phone;
		}

		logEvent(analytics, "signup_attempt", {
			signup_type: phoneNumber ? "phone" : "email",
			signup_value: formData.username,
		});

		dispatch(signupService(formData));

		f7.dialog.preloader(t("Please wait a moment this may take a moment"));
		setTimeout(() => {
			f7.dialog.close();
		}, 2000);
	};
	useUpdateEffect(() => {
		if (loginResponse?.code === STATUS_CODE.SUCCESS) {
			// f7.toast.create({ text: t(loginResponse.message) }).open();
			logEvent(analytics, "signup_success", {
				signup_type: phoneNumber ? "phone" : "email",
				signup_value: formData.username,
			});

			f7router.navigate("/ProfilePage");
		} else {
			f7.toast.create({ text: t(loginResponse.message) }).open();
		}
	}, [loginResponse]);

	const onInputChange = (name, e) => {
		setFormData({
			...formData,
			[name]: e.target.value,
		});
	};

	const handleNameChange = (e) => {
		const enteredName = e.target.value;
		setDisplay_Name(enteredName);
		const namePattern =
			/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2,30}$/;
		setIsvalidName(namePattern === "" || namePattern.test(enteredName));
	};
	const handlePasswordChange = (e) => {
		const enteredPassword = e.target.value;
		const passwordPattern =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/;
		const isValid = passwordPattern.test(enteredPassword);

		setValidPassword(isValid); // Update the state based on validity
		setPaassword(enteredPassword); // Set the entered password
	};
	const handleLogin = (logindata) => {
		logEvent(analytics, "login_attempt", {
			login_type: "google",
		});

		dispatch(
			socialLoginService({
				email: logindata.email,
				token: logindata.token,
				type: logindata.type,
				device_type: logindata.device_type,
				firstname: logindata.fristname,
				profile_picture: logindata.profile_picture,
			})
		);
	};
	const LoginWithPosh = (e) => {
		e.preventDefault();
		e.stopPropagation();

		logEvent(analytics, "login_attempt", {
			login_type: "posh",
		});

		dispatch(
			loginwithPoshService({
				login: usernameworldnoor,
				password: passwordworldnoor,
			})
		);
	};

	const handlePoshImageClick = () => {
		setIsPopupOpen(true);
	};
	const EmailChange = (e) => {
		const enteredEmail = e.target.value;
		setUsernameworldnoor(enteredEmail);
	};
	const PasswordChange = (e) => {
		const enteredpassword = e.target.value;
		setPasswordworldnoor(enteredpassword);
	};

	return (
		<Page
			name="signup"
			loginScreen
			pageContent={false}
			className="main-bg-bubble">
			<AuthNabvar />
			<PageContent className="flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px]">
				<div className="w-full md:w-[400px]">
					<div className="pl-[30pxz] flex items-center justify-start">
						{/* {f7.views.main.router.previousRoute.name === "login" && ( */}
						<Link back>
							<MaterialIcon icon="arrow_back_ios" size={32} />
						</Link>
						{/* )} */}
						<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
							{t("Sign up")}
						</p>
					</div>
					<List className="list-none">
						<PhoneInput onChange={setPhoneNumber} />
						<ListInput
							type="text"
							placeholder={t("Display Name")}
							className={`input-field-normal list-none w-full mt-5 ${
								display_name && !isValidName > 0
									? "invalid"
									: ""
							}`}
							value={formData.display_name}
							onChange={handleNameChange}
							onInput={(e) => {
								onInputChange("display_name", e);
							}}>
							<div slot="media">
								<Profile
									color="#5d6980"
									variant="Bold"
									size={22}
								/>
							</div>
						</ListInput>
						{!isValidName && display_name !== "" && (
							<p className=" ml-[40px] mt-[10px] error-text">
								{t("Please enter a valid name")}
							</p>
						)}
					</List>
					<List>
						<ListInput
							type={passwordType}
							placeholder={t("Enter password")}
							className={`input-field-normal list-none  mt-5 ${
								password && !validpassword
									? "invalid"
									: password.length >= 8
									? "strong"
									: ""
							}`}
							value={formData.password}
							onChange={handlePasswordChange}
							onInput={(e) => {
								onInputChange("password", e);
							}}>
							<div slot="content-end" className=" cursor-pointer">
								{passwordType === "password" ? (
									<Eye
										onClick={() => setPasswordType("text")}
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								) : (
									<EyeSlash
										onClick={() =>
											setPasswordType("password")
										}
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								)}
							</div>
							<div slot="media">
								<Lock
									color="#5d6980"
									variant="Bold"
									size={22}
								/>
							</div>
						</ListInput>
						{!validpassword && password !== "" && (
							<p className="w-[345px] ml-[40px] mt-[10px] error-text">
								{" "}
								{t("Your password must contain:")}
								<br />
								{t("8 character minimum")}
								<br />
								{t(
									"Uppercase and lowercase letters and numbers"
								)}
								<br />
								{t(
									"Inclusion of at least one special character"
								)}
							</p>
						)}
					</List>
					<List>
						<ListItem className="mt-5 button-container mr-[-15px]">
							<Button
								type="submit"
								fill
								onClick={signUp}
								className="w-full"
								disabled={
									!formData.display_name.length ||
									formData.password.length < 8 ||
									!validpassword ||
									!phoneNumber?.phone ||
									phoneNumber.validationError ||
									!phoneNumber?.country ||
									isLoading
								}>
								{isLoading ? (
									<Preloader color="white" />
								) : (
									t("Sign up")
								)}
							</Button>
						</ListItem>
					</List>
					<List>
						<ListItem className="mr-[-25px] mt-4 pl-1">
							<div className="w-full flex mr-[-15px] items-center gap-2">
								<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
								<p className="text-[14px] text-[--f7-chat-heading-color]">
									{t("OR SIGN UP WITH")}
								</p>
								<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
							</div>
						</ListItem>
						<ListItem className="mr-[-30px] pl-1">
							<div className=" w-full flex justify-between">
								<span className="cursor-pointer pt-[10px]">
									<Googlelogin onSignIn={handleLogin} />
								</span>
								<span className="cursor-pointer">
									<AppleLoginButton />
								</span>
								{/* <span>
					<LazyLoaderWraper src={Facebook} alt="Icon" />
				</span> */}
								<span
									className="cursor-pointer"
									onClick={handlePoshImageClick}>
									<LazyLoaderWraper src={Posh} alt="Icon" />
								</span>
							</div>
						</ListItem>
						<Popup
							className="w-[501px] h-[547px]"
							opened={isPopupOpen}
							onPopupClosed={() => setIsPopupOpen(false)}>
							<PageContent className="flex mt-[10px] px-[20px] md:px-0 justify-center flex-wrap gap-[100px] ">
								<div className="w-full md:w-[400px]">
									<div className="grid justify-evenly">
										<span className="cursor-pointer flex justify-center items-center">
											<img src={Posh} alt="Icon" />
											<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
												{t("WN Social")}
											</p>
										</span>
										<p className="w-[373px] h-[69px] mb-[10px] font-normal text-[15px] leading-[22.50px] tracking-[0.35px] font-[Poppins]">
											{t(
												"WN Social is your gateway to enter all apps developed by Posh. Create your WN Social ID or login if you already have one."
											)}
										</p>
									</div>
									<List
										className="list-none m-0"
										form
										onSubmit={LoginWithPosh}>
										<ListInput
											type="text"
											placeholder={t(
												"Enter email address"
											)}
											className="input-field-normal w-full mb-2.5"
											value={usernameworldnoor}
											onInput={(e) => {
												setUsernameworldnoor(
													e.target.value
												);
											}}
											required
											onChange={EmailChange}>
											<div slot="media">
												<Profile
													color="#5d6980"
													variant="Bold"
													size={22}
												/>
											</div>
										</ListInput>
										<ListInput
											type={passwordvalue}
											placeholder={t("Enter password")}
											className="input-field-normal "
											value={passwordworldnoor}
											onInput={(e) => {
												setPasswordworldnoor(
													e.target.value
												);
											}}
											required
											onChange={PasswordChange}>
											<div
												slot="content-end"
												className="cursor-pointer">
												{passwordvalue ===
												"password" ? (
													<Eye
														onClick={() =>
															setPasswordValue(
																"text"
															)
														}
														color="#5d6980"
														variant="Bold"
														size={24}
													/>
												) : (
													<EyeSlash
														onClick={() =>
															setPasswordValue(
																"password"
															)
														}
														color="#5d6980"
														variant="Bold"
														size={24}
													/>
												)}
											</div>
											<div slot="media">
												<Lock
													color="#5d6980"
													variant="Bold"
													size={22}
												/>
											</div>
										</ListInput>
										<ListItem className="no-padding">
											<Button
												type="submit"
												fill
												preloader
												preloaderSize={24}
												preloaderColor="white"
												loading={isLoading}
												disabled={isLoading}
												className="w-full"
												onClick={LoginWithPosh}>
												{t("Login to WN Social")}
											</Button>
										</ListItem>
										<ListItem className="mt-1 pl-1">
											<div className=" w-full flex justify-center">
												<p className="text-[16px]  text-[--f7-body-text]">
													<span className="opacity-70">
														{" "}
														{t("Or")}{" "}
													</span>
													<Link
														className="text-[--f7-md-primary]"
														href="/signup">
														{" "}
														{t("Create an Account")}
													</Link>
												</p>
											</div>
										</ListItem>
										<ListItem>
											<p className="font-normal text-[13px] leading-[19.5px] tracking-[0.35px] font-[Poppins]">
												{t(
													"WN Social values your privacy and security.By logging into KT Messenger through WN Social, rest assured that your information and data are treated with the utmost confidentiality. Our commitment to your privacy means that your data will not be shared or compromised."
												)}
											</p>
										</ListItem>
									</List>
								</div>
							</PageContent>
						</Popup>
						<ListItem className="mr-[-30px] mt-4 pl-1">
							<div className=" w-full flex justify-center">
								<p className="text-[16px]  text-[--f7-body-text]">
									<span className="opacity-70">
										{t("By signing up you agree to our")}
										&nbsp;
										<Link
											onClick={() => setTermsOpened(true)}
											className="text-[--f7-md-primary] ">
											&nbsp;{t("Terms & Condition")}&nbsp;
										</Link>
										{t("and")}
									</span>
									<Link
										onClick={() => setPrivacyOpened(true)}
										className="text-[--f7-md-primary]">
										&nbsp;{t("Privacy Policy")}&nbsp;
									</Link>
								</p>
							</div>
						</ListItem>
					</List>
				</div>
				<div className="w-full md:w-[400px] flex flex-col items-center justify-center">
					<LazyLoaderWraper src={QR} alt="Icon" />
				</div>
			</PageContent>
			<ExternalPagePopup
				url="https://www.ktmessenger.com/legal/privacy.html"
				opened={privacyOpened}
				onClosed={() => setPrivacyOpened(false)}
			/>
			<ExternalPagePopup
				url="https://www.ktmessenger.com/terms.html"
				opened={termsOpened}
				onClosed={() => setTermsOpened(false)}
			/>
		</Page>
	);
};

export default SignupPage;
