import {
	Button,
	Link,
	List,
	ListButton,
	ListInput,
	ListItem,
	NavLeft,
	NavRight,
	NavTitle,
	Navbar,
	Page,
	Popover,
	Popup,
	Tab,
	f7,
} from "framework7-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../components/misc/materialIcon";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import userImg from "../../../../assets/images/placeholders/user.png";
import { useDispatch, useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import {
	Call,
	Camera,
	Edit,
	Import,
	Location,
	Sms,
	User,
	UserSquare,
} from "iconsax-react";
import "./style.css";
import { ProfileUpdateService } from "../../../../redux/features/userSlice/profileUpdate";
import { Camera as CameraComponent } from "react-camera-pro";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import {
	createOpenDialog,
	dataURLtoFile,
	isValidDataUrl,
} from "../../../../utils/functions";
import { useUpdateEffect } from "react-use";
import { STATUS_CODE } from "../../../../constants";

const Profile = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [edit, setEdit] = useState(false);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [userEdit, setUserEdit] = useState({});
	const cameraComponent = useRef(null);
	const profilePictureRef = useRef(null);
	const [activateCamera, setActivateCamera] = useState(false);
	const [mediaSelected, setMediaSelected] = useState(false);
	const [viewerOpen, setViewerOpen] = useState(false);
	const [imageFile, setImageFile] = useState(null);
	const [imageAltered, setImageAltered] = useState(false);
	const userData = useLiveQuery(() =>
		db.users.where({ id: loginResponse?.data?.id }).first()
	);
	const user = userData || loginResponse?.data;

	const { userProfileUpdateResponse, isLoading } = useSelector(
		(state) => state.profileUpdateSlice
	);

	useEffect(() => {
		if (user) {
			setUserEdit({
				first_name:
					user?.name ||
					user?.kalam_name ||
					user?.firstname + " " + user?.lastname,
				bio: user?.bio,
				...(!user?.email && { email: "" }),
				// ...(!user.phone && { phone: "" }),
				profile_image: user?.profile_image,
			});
		}
	}, [user]);

	const openFile = () => {
		const fileInput = createOpenDialog();
		fileInput.onchange = (_) => {
			let files = Array.from(fileInput.files);
			setImageFile(files[0]);
			setMediaSelected(true);
			fileInput.remove();
		};
		fileInput.click();
	};

	useUpdateEffect(() => {
		if (!profilePictureRef.current) return;
		setTimeout(() => {
			imageFile &&
				profilePictureRef.current &&
				profilePictureRef.current.setImage(imageFile);
		}, 500);
	}, [imageFile]);

	const handleCrop = () => {
		const imageUrl =
			profilePictureRef.current &&
			profilePictureRef.current.getImageAsDataUrl(1);
		setUserEdit((userEdit) => ({ ...userEdit, profile_image: imageUrl }));
		setImageAltered(true);
		setImageFile(null);
		setMediaSelected(false);
		f7.popup.close();
		// setEdit(false);
	};

	const profileSubmit = () => {
		let formdata = new FormData();
		formdata.append("first_name", userEdit?.first_name);
		formdata.append("bio", userEdit?.bio);
		userEdit?.email && formdata.append("email", userEdit?.email);
		// userEdit?.phone && formdata.append("phone", userEdit?.phone);
		if (isValidDataUrl(userEdit?.profile_image) && imageAltered) {
			formdata.append(
				"profile_image",
				dataURLtoFile(
					userEdit.profile_image,
					`photo-${loginResponse?.data?.id}.jpg`
				)
			);
		}
		dispatch(ProfileUpdateService(formdata));
		setImageAltered(false);
		db.users.where({ id: loginResponse.data.id }).modify((value, ref) => {
			ref.value = {
				...value,
				...(userEdit?.email && { email: userEdit?.email }),
				...(userEdit?.bio && { bio: userEdit?.bio }),
				...(userEdit.first_name && {
					firstname: userEdit.first_name.split(" ")[0],
					lastname: userEdit.first_name.split(" ")[1],
					display_name: userEdit.first_name,
					name: userEdit.first_name,
					kalam_name: userEdit.first_name,
				}),
			};
		});
	};

	useUpdateEffect(() => {
		if (userProfileUpdateResponse?.code === STATUS_CODE.SUCCESS) {
			setEdit(false);
			if (userEdit?.email) {
				//|| userEdit?.phone) {
				db.users
					.update(loginResponse?.data?.id, {
						...(userEdit?.email && { email: userEdit?.email }),
						// ...(userEdit?.phone && { phone: userEdit?.phone }),
					})
					.then(async function (lastKey) {});
			}
			setUserEdit({
				first_name: user?.name,
				bio: user?.bio,
				...(!user?.email && { email: "" }),
				...(!user.phone && { phone: "" }),
				profile_image: user?.profile_image,
			});
			setImageAltered(false);
		}
	}, [userProfileUpdateResponse]);

	return (
		<Tab id="profileTab">
			<Navbar>
				{!edit && (
					<NavLeft>
						<Link tabLink="#chatTab">
							<MaterialIcon
								size={48}
								icon="arrow_back"
								fill
								color="--f7-chat-heading-color"
							/>
						</Link>
					</NavLeft>
				)}
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Profile")}
				</NavTitle>
				<NavRight>
					{!edit ? (
						<Button
							onClick={() => {
								setEdit(true);
							}}
							className="h-[40px]">
							<Edit size="20" color="#5d6980" />
						</Button>
					) : (
						<Button
							onClick={() => {
								setEdit(false);
							}}
							className="h-[40px]">
							<MaterialIcon
								icon="close"
								size={24}
								color="#5d6980"
								className="text-[20px]"
							/>
						</Button>
					)}
				</NavRight>
			</Navbar>
			<div className="page-content p-5 flex flex-col items-center justify-start gap-[10px]">
				<div className="relative max-w-[150xp] max-h-[150px]">
					<LazyLoaderWraper
						src={
							!edit
								? user?.profile_image || userImg
								: userEdit?.profile_image || userImg
						}
						placeholder={userImg}
						height={150}
						width={150}
						alt=""
						className="cursor-pointer rounded-full max-w-[150px] max-h-[150px] object-cover p-0.5"
						onClick={() => {
							setViewerOpen(true);
						}}
					/>
					{edit && (
						<Button
							className="absolute w-full h-full rounded-full left-0 top-0 flex-col bg-[#00000099] gap-[5px]"
							popoverOpen=".image-menu">
							<Camera
								id="image-popover-mount"
								size="30"
								color="#fff"
								variant="Bold"
							/>
							<h1 className="max-w-[100px] text-white font-normal text-sm uppercase whitespace-normal">
								{t("Change profile image")}
							</h1>
						</Button>
					)}
				</div>
				<List
					mediaList={!edit}
					className="w-full bg-[#F6F6F6] rounded-[20px] mt-0">
					{!edit && user?.profile_id && (
						<ListItem
							className="profileListItem"
							title={t("Profile ID")}
							text={user?.profile_id}
							onClick={() => {
								navigator.clipboard.writeText(user?.profile_id);
								f7.toast
									.create({
										text: t("Copied"),
										position: "center",
										closeTimeout: 2000,
									})
									.open();
							}}>
							<UserSquare
								slot="media"
								size="24"
								color="#0E99FE"
								variant="Bold"
							/>
							<MaterialIcon
								slot="after"
								icon="content_copy"
								color="#b7b7b7"
								className="cursor-pointer text-[16px]"
							/>
						</ListItem>
					)}
					{!edit ? (
						<ListItem
							className="profileListItem"
							title={t("Display Name")}
							text={
								user?.name ||
								user?.kalam_name ||
								user?.firstname + " " + user?.lastname
							}>
							<User
								slot="media"
								size="24"
								color="#0E99FE"
								variant="Bold"
							/>
						</ListItem>
					) : (
						<ListInput
							type="text"
							label={t("Display Name")}
							placeholder={t("Display Name")}
							info={t(
								"This is not your username. This name will be visible to your KT Messenger contacts."
							)}
							className="profileListItem"
							value={userEdit?.first_name}
							onChange={(e) =>
								setUserEdit({
									...userEdit,
									first_name: e.target.value,
								})
							}
						/>
					)}
					{!edit && (
						<ListItem
							className="profileListItem"
							title={t("Username")}
							text={user?.username}>
							<MaterialIcon
								slot="media"
								icon="alternate_email"
								size={24}
								weight={700}
								color="#0E99FE"
								className="text-[24px]"
							/>
						</ListItem>
					)}
					{!edit ? (
						<ListItem
							className="profileListItem"
							title={t("About me")}
							text={user?.bio}>
							<MaterialIcon
								fill
								slot="media"
								icon="info"
								size={24}
								weight={500}
								color="#0E99FE"
								className="text-[24px]"
							/>
						</ListItem>
					) : (
						<ListInput
							type="text"
							label={t("About me")}
							placeholder={t("About me")}
							className="profileListItem"
							value={userEdit?.bio}
							onChange={(e) =>
								setUserEdit({
									...userEdit,
									bio: e.target.value,
								})
							}
						/>
					)}
					{!edit ? (
						<ListItem
							className="profileListItem"
							title={t("Email Address")}
							text={user?.email}>
							<Sms
								slot="media"
								size="24"
								color="#0E99FE"
								variant="Bold"
							/>
						</ListItem>
					) : (
						<>
							{!user?.email ? (
								<ListInput
									type="email"
									label={t("Email Address")}
									placeholder={t("Email Address")}
									className="profileListItem"
									value={userEdit?.email}
									onChange={(e) =>
										setUserEdit({
											...userEdit,
											email: e.target.value,
										})
									}
								/>
							) : null}
						</>
					)}
					{
						!edit ? (
							<ListItem
								className="profileListItem"
								title={t("Phone Number")}
								text={
									user?.phone ||
									user?.mobile ||
									user?.kalam_number
								}>
								<Call
									slot="media"
									size="24"
									color="#0E99FE"
									variant="Bold"
								/>
							</ListItem>
						) : null
						// <>
						// 	{user?.phone ? (
						// 		<ListInput
						// 			type="tel"
						// 			label={t("Phone Number")}
						// 			placeholder={t("Phone Number")}
						// 			className="profileListItem"
						// 			value={userEdit?.phone}
						// 			onChange={(e) =>
						// 				setUserEdit({ ...userEdit, phone: e.target.value })
						// 			}
						// 		/>
						// 	) : null}
						// </>
					}
					{!edit ? (
						<ListItem
							className="profileListItem"
							title={t("Location")}
							text={user?.country}>
							<Location
								slot="media"
								size="24"
								color="#0E99FE"
								variant="Bold"
							/>
						</ListItem>
					) : null}
				</List>
				{edit && (
					<div className="w-full absolute bottom-[--f7-button-height]  px-5">
						<Button
							fill
							text={t("Save Changes")}
							onClick={profileSubmit}
							disabled={isLoading}
							preloader
							loading={isLoading}
							preloaderColor="#fff"
							preloaderSize={36}></Button>
					</div>
				)}
			</div>
			<Popover
				targetEl="#image-popover-mount"
				arrow={false}
				backdrop={false}
				className="image-menu left-[190px] top-[150px]">
				<List>
					<ListButton
						text={t("View Photo")}
						className=""
						popoverClose
						onClick={() => {
							setViewerOpen(true);
						}}
					/>
					<ListButton
						text={t("Take Photo")}
						className=""
						popoverClose
						onClick={() => {
							setActivateCamera(true);
						}}
					/>
					<ListButton
						text={t("Upload Photo")}
						className=""
						popoverClose
						onClick={openFile}
					/>
					{/* <ListButton text={t("Emoji & Sticker")} className="" popoverClose />
					<ListButton text={t("Remove Photo")} color="red" popoverClose /> */}
				</List>
			</Popover>
			<Popup
				className="camera-popup"
				opened={activateCamera}
				onPopupClose={() => setActivateCamera(false)}>
				<Page>
					<Button
						className="absolute top-0 right-0  w-[--f7-button-height] p-0"
						onClick={() => {
							f7.popup.close();
						}}>
						<MaterialIcon
							icon="close"
							size={24}
							color="#5d6980"
							className="text-[24px]"
						/>
					</Button>
					<div className="h-full flex flex-col justify-center items-center profile-camera">
						{activateCamera && (
							<CameraComponent ref={cameraComponent} />
						)}
						<Button
							fill
							onClick={() => {
								setImageFile(
									dataURLtoFile(
										cameraComponent?.current?.takePhoto(),
										"photo.jpg"
									)
								);
								setActivateCamera(false);
								setMediaSelected(true);
							}}
							className="px-[100px] mt-5">
							<MaterialIcon
								icon="photo_camera"
								size={24}
								color="#fff"
								className="mr-5"
							/>
							{t("Take Photo")}
						</Button>
					</div>
				</Page>
			</Popup>
			<Popup
				className="profile-picture-edit-popup"
				opened={mediaSelected}
				onPopupClose={() => setMediaSelected(false)}>
				<Page>
					<Button
						className="absolute top-0 right-0  w-[--f7-button-height] p-0"
						onClick={() => {
							f7.popup.close();
						}}>
						<MaterialIcon
							icon="close"
							size={24}
							color="#5d6980"
							className="text-[24px]"
						/>
					</Button>
					<div className="h-full flex flex-col justify-center items-center gap-[10px]">
						<ProfilePicture
							ref={profilePictureRef}
							image={userEdit?.profile_image || userImg}
							useHelper={true}
							frameSize={360}
							cropSize={360}
							frameFormat="circle"
							onImageRemoved={() => setMediaSelected(false)}
						/>
						<Button
							fill
							className="px-[100px]"
							onClick={handleCrop}>
							{t("Crop & Save")}
						</Button>
					</div>
				</Page>
			</Popup>
			<Popup
				tabletFullscreen
				className="dp-viewer bg-transparent"
				opened={viewerOpen}
				onPopupClose={() => setViewerOpen(false)}>
				<Page className="bg-black/60">
					<Navbar transparent className="mt-[30px]">
						<NavLeft transparent className="ms-[30px]">
							<LazyLoaderWraper
								src={user?.profile_image || userImg}
								placeholder={userImg}
								height={45}
								width={45}
								alt=""
								className="rounded-full align-bottom"
								wrapperclassname="rounded-full align-bottom"
							/>
							<div className="flex flex-col justify-center items-start ml-[10px]">
								<span className="dark-theme text-body text-2xl font-medium text-ellipsis max-w-[335px]">
									{user?.name ||
										user?.kalam_name ||
										user?.firstname + " " + user?.lastname}
								</span>
							</div>
						</NavLeft>
						<NavRight className="me-[30px]">
							<Link
								href={
									!edit
										? user?.profile_image
										: userEdit?.profile_image
								}
								external
								className="h-[40px] w-[40px]"
								download
								target="_blank">
								<Import size={24} color="#A6A9AF" />
							</Link>
							{/* <Button className="h-[40px] w-[40px]" onClick={openShareDialog}>
								<MaterialIcon
									fill
									icon="share"
									size={24}
									color="#A6A9AF"
									className="text-2xl"
									weight={400}
									grad={0}
								/>
							</Button> */}
							<Button onClick={() => setViewerOpen(false)}>
								<MaterialIcon
									icon="close"
									className="text-2xl cursor-pointer"
									size={24}
									weight={70}
									height={70}
									color="#A6A9AF"
								/>
							</Button>
						</NavRight>
					</Navbar>
					<div className="h-full flex flex-col justify-center items-center">
						<LazyLoaderWraper
							src={
								!edit
									? user?.profile_image || userImg
									: userEdit?.profile_image || userImg
							}
							placeholder={userImg}
							height={640}
							width={640}
							alt=""
						/>
					</div>
				</Page>
			</Popup>
		</Tab>
	);
};

export default Profile;
