import { createRoot } from "react-dom/client";
import {
	Button,
	List,
	ListItem,
	MessagebarAttachment,
	MessagebarAttachments,
	Page,
	Popover,
	Popup,
	Sheet,
	f7,
	useStore,
	NavTitle,
	NavRight,
	Link,
	Navbar,
	Segmented,
	Toggle,
} from "framework7-react";
import {
	Add,
	Camera,
	DocumentText,
	Gallery,
	Location,
	Microphone2,
	Profile,
	RecordCircle,
	Send,
	Sms,
	StopCircle,
} from "iconsax-react";
import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKey, useUpdateEffect, useWindowSize } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import { SocketContext } from "../../../socket";
import {
	ImageToBase64,
	addPronoun,
	base64ToFile,
	blobToFile,
	convertToMillisecondsPrecision,
	createCancelableFunction,
	createOpenDialog,
	dataURLtoFile,
	formatBytes,
	generateThumbnail,
	getFileType,
	getFileTypeByName,
	isDuplicateFile,
	removeMD,
	truncateText,
} from "../../../utils/functions";
import MaterialIcon from "../../misc/materialIcon";
import { v4 as uuidv4 } from "uuid";
import { ENDPOINTS } from "../../../constants/socket";
import { uploadFileService } from "../../../redux/features/chatSlice/uploadFile";
import { AI_URL, STATUS_CODE } from "../../../constants";
import { db } from "../../../js/db";
import LightboxThumbnail from "../../misc/lightboxThumbnail";
import { useTranslation } from "react-i18next";
import EmojiPicker from "emoji-picker-react";
import { MentionsInput, Mention } from "react-mentions";
import { useLiveQuery } from "dexie-react-hooks";
import userimg from "../../../assets/images/placeholders/user.png";
import blank_placeholder from "../../../assets/images/placeholders/blank.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import DndContainer from "../DndContainer";
import PdfViewer from "../../misc/PdfViewer";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import { fetchMeta } from "../../../redux/features/metaSlice";
import { SkeletonBlock } from "skeleton-elements/react";
import "./style.css";
import MapWithLocation from "../MapWithLocation";
import Webcam from "react-webcam";
import locationpic from "../../../assets/images/placeholders/locationpic.png";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import urlJoin from "url-join";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";

const Messagebar = ({ id, toggleAudioInput, hideUnread }) => {
	id = id ? (id !== "posh_ai" ? parseInt(id) : id) : null;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const messagebarRef = useRef(null);
	const messageinput = useRef(null);
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const desktop = useMemo(() => width >= 775, [width]);
	const [inputExtended, setInputExtended] = useState("");
	const [sheetOpened, setSheetOpened] = useState(false);
	const [attachments, setAttachments] = useState([]);
	const [attachmentsOpen, setAttachmentsOpen] = useState(false);
	const [captions, setCaptions] = useState([]);
	const [messageText, setMessageText] = useState("");
	const [activeDocument, setActiveDocument] = useState({});
	const [activeIndex, setActiveIndex] = useState(0);
	const [compress, setCompress] = useState(false);
	const reply = useStore("reply");
	const replyCache = useRef(null);
	const edit = useStore("edit");
	const typingTimer = useRef(null);
	const doneTypingInterval = useRef(700);
	const [metaAvailable, setMetaAvailable] = useState(null);
	const [metaHidden, setMetaHidden] = useState(false);
	const [locationPopupOpened, setLocationPopupOpened] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const [mapPosition, setMapPosition] = useState(false);
	const cameraRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const supportedContraints = navigator.mediaDevices.getSupportedConstraints();
	const [videoDuration, setVideoDuration] = useState(0);
	const [cameraOpened, setCameraOpened] = useState(false);
	const [cameraMode, setCameraMode] = useState("photo");
	const [cameraRecording, setCameraRecording] = useState(false);
	const [recordedChunks, setRecordedChunks] = useState([]);
	const [mimeCheck, setMimeCheck] = useState(true);
	const textSelected = useRef(false);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const AISessionId = useStore("AISessionId");
	const [AIWebSearch] = useLocalStorageState("AIWebSearch");
	const [refreshPage, setRefreshPage] = useState(false);
	const draftCache = useRef(null);
	const { useProfileResponse: user } = useSelector(
		(state) => state.profileSlice
	);
	const { uploadFileResponse, isLoading } = useSelector(
		(state) => state.uploadFileSlice
	);
	const { metaResponse, isLoading: metaLoading } = useSelector(
		(state) => state.metaSlice
	);

	const { t } = useTranslation();
	const socket = useContext(SocketContext);
	const members = useLiveQuery(
		async () => {
			if (!id || id === "null" || isNaN(id)) return [];
			const data = await db.members.where({ chat_id: id }).toArray();
			const filteredMembers = data.filter(
				(member) => member.id != loginResponse.data.id
			);
			return filteredMembers.map((member) => ({
				id: member.id,
				display:
					member.nickname ||
					member.name ||
					member.firstname + " " + member.lastname,
				avater: member.profile_image || userimg,
			}));
		},
		[id],
		[]
	);

	const metaDatas = useLiveQuery(() => db.metaDatas.toArray(), [], []);

	useUpdateEffect(() => {
		if (!id || id === "null") return;
		setAttachments([]);
		setCaptions([]);
		setActiveDocument({});
		setSheetOpened(false);
		setCompress(false);
		setMetaAvailable(false);
		setMetaHidden(false);
		setLocationPopupOpened(false);
		setShowMap(false);
		setMapPosition(false);
		setVideoDuration(0);
		setCameraOpened(false);
		setCameraRecording(false);
		setRecordedChunks([]);
		setMimeCheck(true);
		setRefreshPage(false);
		if (draftCache.current) {
			db.drafts
				.put(draftCache.current)
				.then(() => (draftCache.current = null))
				.catch((e) => console.error(e));
			setMessageText("");
		}
		db.drafts
			.get(id)
			.then((draft) => {
				if (draft && draft.text) {
					setMessageText(draft.text);
					setTimeout(calculateHeight, 50);
				} else {
					setMessageText("");
					draftCache.current = null;
					setTimeout(calculateHeight, 50);
				}
			})
			.catch((e) => {
				setMessageText("");
				draftCache.current = null;
				setTimeout(calculateHeight, 50);
			});
	}, [id]);

	const handleTextChange = (e) => {
		setMetaAvailable(null);
		if (e.target.value?.trim() !== "") {
			if (captions.length > 0) {
				setCaptions((prevCaptions) =>
					prevCaptions.map((caption, index) =>
						index === activeIndex ? e.target.value : caption
					)
				);
			} else {
				setMessageText(e.target.value);
				typingTimer.current && clearTimeout(typingTimer.current);
				typingTimer.current = setTimeout(() => {
					if (!edit) {
						draftCache.current = { chat_id: id, text: e.target.value };
					}
				}, doneTypingInterval.current);
			}
		} else {
			if (captions.length > 0) {
				setCaptions((prevCaptions) =>
					prevCaptions.map((caption, index) =>
						index === activeIndex ? "" : caption
					)
				);
			} else {
				setMessageText("");
				draftCache.current = null;
				!edit && db.drafts.delete(id);
			}
		}
		calculateHeight();
	};

	const handleKeyDown = (e) => {
		if (!e.shiftKey && e.key === "Enter") {
			e.preventDefault();
			if (messageText.trim().length > 0 || attachments.length > 0) {
				sendMessage();
			}
		}
	};

	const attachmentsVisible = () => {
		return attachments.length > 0;
	};

	const popupsShouldRender = () => {
		return messageinput.current ? true : false;
	};

	const showMicButton = () => {
		if (
			attachments.length > 0 ||
			messageText.length > 0 ||
			showMap ||
			id === "posh_ai"
		)
			//|| edit)
			return false;
		else return true;
	};

	const placeholder = () => {
		return attachments.length > 0
			? t("Add message or Send")
			: t("Type a message here..");
	};

	useLayoutEffect(() => {
		function handlePaste(e) {
			if (document.activeElement == messageinput?.current) {
				try {
					navigator.clipboard.read().then((clipboardItems) => {
						if (clipboardItems.length === 0) return;
						if (clipboardItems[0].types.includes("text/plain")) {
							setTimeout(() => {
								calculateHeight();
							}, 100);
						}
						if (clipboardItems[0].types.includes("image/png")) {
							clipboardItems[0].getType("image/png").then((blob) => {
								setAttachments((prevAttachments) => {
									prevAttachments?.length === 0 && setActiveDocument({});
									prevAttachments?.length === 0 && setActiveIndex(0);
									return [...prevAttachments, blobToFile(blob)];
								});
								setCaptions((prevCaptions) => [
									...prevCaptions,
									!prevCaptions?.length && messageText ? messageText : "",
								]);
								setCompress(true);
								setTimeout(() => {
									calculateHeight();
								}, 100);
							});
						}
					});
					messageinput.current && messageinput.current.focus();
				} catch (e) {
					console.error(e);
				}
			} else {
				return;
			}
		}
		window.addEventListener("paste", handlePaste);

		return () => {
			window.removeEventListener("paste", handlePaste);
		};
	}, [messageText]);

	useEffect(() => {
		messageinput.current && messageinput.current.focus();
	}, []);

	const deleteAttachment = (index) => {
		attachments.splice(index, 1);
		captions.splice(index, 1);
		setAttachments([...attachments]);
		setCaptions([...captions]);
	};

	useUpdateEffect(() => {
		if (attachments.length === 0) {
			setActiveDocument({});
			setActiveIndex(0);
			setAttachmentsOpen(false);
			setTimeout(() => {
				const htmlEl = document.getElementsByTagName("html")[0];
				if (htmlEl.classList.contains("with-modal-popup")) {
					htmlEl.classList.remove("with-modal-popup");
				}
			}, 100);
			return;
		}

		if (Object.keys(activeDocument).length === 0) {
			setActiveDocument({
				uri: window.URL.createObjectURL(attachments[0]),
				fileName: attachments[0].name,
			});
		} else {
			setActiveDocument({
				uri: window.URL.createObjectURL(attachments[attachments.length - 1]),
				fileName: attachments[attachments.length - 1].name,
			});
			setActiveIndex(attachments.length - 1);
		}
		setTimeout(() => {
			setAttachmentsOpen(true);
		}, 100);
		//write other logic if we want to show the latest added file from popup here
	}, [attachments]);

	useUpdateEffect(() => {
		if (uploadFileResponse?.code === STATUS_CODE.SUCCESS) {
			const uploadedFile = uploadFileResponse.data[0];

			socket.emit(
				ENDPOINTS.SEND_MESSAGE,
				JSON.stringify({
					message: uploadedFile.type == "document" ? uploadedFile.message : "",
					chat_id: uploadedFile.chat_id,
					receiver_id: 0,
					identifier: uploadedFile.identifier,
					group_id: uploadedFile.group_id,
					is_group: uploadedFile.is_group,
					mType: uploadedFile.type,
					file_type: uploadedFile.file_type,
					file_size: uploadedFile.file_size,
					caption: uploadedFile.caption || "",
					file_id: uploadedFile.file_id,
					audio_url: uploadedFile.file_url,
					thumbnail: uploadedFile.thumbnail,
					...(uploadedFile.duration && {
						duration: uploadedFile.duration,
					}),
					...(replyCache.current && { reply_id: replyCache.current.id }),
				}),
				(response) => {
					replyCache.current = null;
					db.messages
						.where({ identifier: response.identifier })
						.modify((value, ref) => {
							ref.value = {
								...value,
								...response,
								...(!response.is_read && { is_read: 0 }),
								unix_time: convertToMillisecondsPrecision(response.unix_time),
								file_id: uploadedFile.file_id,
								audio_url: uploadedFile.file_url,
								thumbnail: uploadedFile.thumbnail,
							};
						})
						.then(() => {
							f7.store.dispatch("removeUploadProgress", uploadedFile.identifier);
							f7.store.dispatch("setUploadIdentifier", null);
							f7.emit("messagesUpdated");
						});
				}
			);
			setSheetOpened(false);
			f7.store.dispatch("setReply", null);
		} else {
			uploadFileResponse &&
				f7.toast.create({ text: uploadFileResponse.message }).open();
		}
	}, [uploadFileResponse]);

	const processMessage = async (
		attachment,
		identifier,
		is_group,
		group_id,
		caption,
		thumbnail = null,
		index
	) => {
		const unix_time = convertToMillisecondsPrecision(Date.now() + index);
		await saveMessage(
			messageText,
			identifier,
			compress ? getFileType(attachment) : "document",
			caption,
			thumbnail,
			attachment,
			is_group,
			group_id,
			unix_time
		);

		hideUnread();
		document.body.classList.remove("expanded-messagebar");
		socket?.emit(
			ENDPOINTS.READ_ALL_MESSAGES,
			JSON.stringify({
				chat_id: id,
			})
		);

		let formData = new FormData();
		formData.append("file", attachment);
		thumbnail && formData.append("thumbnail", dataURLtoFile(thumbnail), "thumb.jpg");
		formData.append("type", compress ? getFileType(attachment) : "document");
		formData.append("is_group", is_group);
		formData.append("group_id", group_id);
		formData.append("identifier", identifier);
		formData.append("chat_id", id);
		formData.append("caption", caption);
		formData.append("mime_check", mimeCheck);
		videoDuration && formData.append("duration", parseInt(videoDuration / 1000));

		return dispatch(uploadFileService({ data: formData, req_id: identifier }));
	};

	const sendMessage = async (fixedmessage = null) => {
		typingTimer.current && clearTimeout(typingTimer.current);
		f7.emit("clearChatSearch");
		f7.popup.close();
		const text = fixedmessage ? fixedmessage.trim() : messageText.trim();
		const _attachments = attachments.length > 0 ? attachments : [];
		const _captions = captions.length > 0 ? captions : [];
		const isOnlyNewlines = (str) => /^[\n\r]+$/.test(str);
		if (isOnlyNewlines(text)) {
			setMessageText("");
			draftCache.current = null;
			return;
		}
		setMessageText("");
		draftCache.current = null;
		setAttachments([]);
		setCaptions([]);
		setAttachmentsOpen(false);
		setMetaHidden(false);
		db.drafts.delete(id);
		setTimeout(() => {
			calculateHeight();
		}, 100);
		messageinput.current && messageinput.current.focus();
		if (edit) {
			const editedMessage = edit;
			f7.store.dispatch("setEdit", null);
			socket.emit(
				editedMessage.type === "text"
					? ENDPOINTS.EDIT_MESSAGE
					: ENDPOINTS.EDIT_CAPTION,
				JSON.stringify({
					message_id: editedMessage.id,
					...(editedMessage.type === "text"
						? { message: text }
						: { caption: text }),
					...(editedMessage.type !== "text" && {
						file_id: editedMessage.file_id,
					}),
					chatId: editedMessage.chat_id,
				}),
				(response) => {
					db.messages
						.where({ id: response.message_id })
						.modify((value, ref) => {
							ref.value = {
								...value,
								...response,
								edited: 1,
							};
						});
					db.chats.where({ chat_id: response.chat_id }).modify((value, ref) => {
						ref.value = {
							...value,
							...(response.message_id === value.last_message_id && {
								message: response.message,
							}),
						};
					});
					messageinput.current && messageinput.current.focus();
				}
			);

			return;
		}
		if (_attachments.length > 0) {
			// const includes_document = _attachments.some(
			// 	(attachment) =>
			// 		!attachment.type.includes("video") &&
			// 		!attachment.type.includes("image")
			// );
			const is_group = !compress
				? false
				: _attachments.length >= 4
				? true
				: false;
			const groupid = uuidv4();

			const uploadPromises = [];

			for (let key = 0; key < _attachments.length; key++) {
				const attachment = _attachments[key];
				const identifier = uuidv4();
				const group_id = is_group === false ? identifier : groupid;
				f7.store.dispatch("setUploadIdentifier", identifier);

				if (attachment.type.includes("video")) {
					const video = attachment;
					const vidCaption = _captions[key];
					const dataUrl = await generateThumbnail(video);
					uploadPromises.push(
						processMessage(
							video,
							identifier,
							is_group,
							group_id,
							vidCaption,
							dataUrl,
							key
						)
					);
				} else {
					uploadPromises.push(
						processMessage(
							attachment,
							identifier,
							is_group,
							group_id,
							_captions[key],
							null,
							key
						)
					);
				}
			}

			Promise.allSettled(uploadPromises).then(() => {
				setTimeout(() => {
					const htmlEl = document.getElementsByTagName("html")[0];
					if (htmlEl.classList.contains("with-modal-popup")) {
						htmlEl.classList.remove("with-modal-popup");
					}
				}, 100);
				setAttachments([]);
				setCaptions([]);
				setTimeout(() => {
					calculateHeight();
				}, 100);
				setActiveDocument({});
				setActiveIndex(0);
				if (text.length) messageinput.current.focus();
			});
		} else {
			const identifier = uuidv4();
			if (showMap) {
				await saveMessage(mapPosition, identifier, "location", text);
			} else {
				await saveMessage(text, identifier);
			}
			hideUnread();
			document.body.classList.remove("expanded-messagebar");
			socket?.emit(
				ENDPOINTS.READ_ALL_MESSAGES,
				JSON.stringify({
					chat_id: id,
				})
			);
			if (id !== "posh_ai") {
				socket.emit(
					ENDPOINTS.SEND_MESSAGE,
					JSON.stringify({
						message: showMap ? mapPosition : text,
						chat_id: id,
						receiver_id: 0,
						identifier: identifier,
						group_id: identifier,
						mType: showMap ? "location" : "text",
						...(showMap && { caption: text }),
						...(metaAvailable && {
							preview: JSON.stringify(metaAvailable),
						}),
						...(replyCache.current && { reply_id: replyCache.current.id }),
					}),
					async (response) => {
						replyCache.current = null;
						f7.store.dispatch("setUploadIdentifier", null);
						await db.messages
							.where({ identifier: response.identifier })
							.modify((value, ref) => {
								ref.value = {
									...value,
									...response,
									...(!response.is_read && { is_read: 0 }),
									unix_time: convertToMillisecondsPrecision(response.unix_time),
								};
							});
						await db.chats.where({ chat_id: response.chat_id }).modify({
							last_message_id: response.id,
						});
					}
				);
			} else {
				const unixTime = Date.now();
				const messageObj = {
					allow_forwarding: 1,
					allow_sharing: 1,
					audio_text: "",
					audio_url: "",
					call_duration: 0,
					caption: "",
					chat_id: "posh_ai",
					duration: null,
					delivered: false,
					edited: 0,
					file: null,
					file_id: "",
					file_size: null,
					file_type: null,
					forwarded: 0,
					forwarded_at: null,
					forwarded_unix: "",
					group_files: [],
					group_id: "",
					id: unixTime,
					identifier: "posh_ai_welcome",
					is_group: false,
					is_private: 0,
					is_read: 2,
					language: "en",
					message: "Thinking...",
					nickname: "Posh AI",
					original_audio_text: "",
					original_audio_url: "",
					original_caption: "",
					original_message: "Thinking...",
					owner_id: "posh_ai",
					owner_name: "",
					parent_id: null,
					reactions: {
						like: 0,
						cry: 0,
						excited: 0,
						heart: 0,
						sad: 0,
						dislike: 0,
					},
					reply: "",
					reply_id: "",
					sender_id: "posh_ai",
					sender_name: "Posh AI",
					sequence: 1,
					story: null,
					thumbnail: "",
					type: "text",
					unix_time: unixTime,
				};

				db.messages
					.put(messageObj)
					.then(async (id) => {
						const headers = {
							"Content-Type": "application/json",
							Authorization:
								"Basic " +
								buffer.Buffer.from("user:password").toString("base64"),
						};

						const response = await fetch(urlJoin(AI_URL, "chatbot"), {
							method: "POST",
							headers: headers,
							body: JSON.stringify({
								input: text,
								session_id: AISessionId,
								web_search: AIWebSearch,
							}),
						});

						if (response.ok) {
							const reader = response.body.getReader();
							const decoder = new TextDecoder("utf-8");
							let chunks = [];
							let result = "";
							const msgEl = document.getElementById(id);
							const textEl = createRoot(
								msgEl?.querySelector('span[slot="text"]')
							);
							while (true) {
								const { value, done } = await reader.read();
								if (done) {
									break;
								}
								chunks.push(value);
								result += decoder.decode(value, {
									stream: true,
								});
								textEl?.render(
									<MarkdownPreview
										source={result}
										rehypePlugins={[rehypeSanitize]}
									/>
								);
							}

							result += decoder.decode();
							db.messages.where({ id: id }).modify((value, ref) => {
								ref.value = {
									...value,
									message: result,
									original_message: result,
									updated_at: new Date().toISOString(),
								};
							});
						} else {
							db.messages.delete(id);
							f7.toast
								.create({
									text: t("Failed to get response from Posh AI"),
								})
								.open();
						}
					})
					.catch((ex) => {
						console.error(ex);
					});
			}
			setSheetOpened(false);
			f7.store.dispatch("setReply", null);
			setShowMap(false);
			setLocationPopupOpened(false);
			setMapPosition(null);
			!edit && db.drafts.delete(id);
		}
	};

	const saveMessage = async (
		msg,
		identifier,
		type = "text",
		caption = null,
		thumbnail = null,
		file = null,
		is_group = false,
		group_id = null,
		unix_time = null
	) => {
		const message_time = unix_time || convertToMillisecondsPrecision(Date.now());
		const file_type = file ? getFileType(file) : "";
		const fileUrl =
			file && file_type === "image"
				? await ImageToBase64(file)
				: metaAvailable
				? JSON.stringify(metaAvailable)
				: "";
		const messageObj = {
			allow_forwarding: user?.data?.allow_forwarding || 1,
			allow_sharing: user?.data?.allow_sharing || 1,
			audio_text: "",
			audio_url: fileUrl,
			call_duration: 0,
			caption: caption || "",
			chat_id: id,
			duration: null,
			delivered: false,
			edited: 0,
			file: null,
			file_id: "",
			file_size: file ? file.size : null,
			file_type: file ? file.name.split(".").pop() : null,
			forwarded: 0,
			forwarded_at: null,
			forwarded_unix: "",
			group_files: [],
			group_id: group_id || "",
			id: message_time,
			identifier: identifier,
			is_group: is_group,
			is_private: 0,
			is_read: id !== "posh_ai" ? -1 : 2,
			language: "en",
			message: file ? `Shared ${addPronoun(type)}` : msg,
			nickname: user?.nickname || "",
			original_audio_text: "",
			original_audio_url: "",
			original_caption: caption || "",
			original_message: caption
				? ""
				: file
				? `Shared ${addPronoun(type)}`
				: msg,
			owner_id: 0,
			owner_name: "",
			parent_id: null,
			preview: fileUrl,
			reactions: {
				like: 0,
				cry: 0,
				excited: 0,
				heart: 0,
				sad: 0,
				dislike: 0,
			},
			reply: reply ? JSON.stringify(reply) : "",
			reply_id: reply ? reply.id : "",
			sender_id: loginResponse.data.id,
			sender_name: user?.nickname || user?.firstname || user?.name || "",
			sequence: 1,
			story: null,
			thumbnail: thumbnail || fileUrl,
			type: type,
			unix_time: message_time,
		};

		try {
			const id = await db.messages.put(messageObj);

			if (!id) throw new Error("Failed to save message to DB");
			replyCache.current = reply;
			f7.store.dispatch("setReply", null);

			setTimeout(() => {
				f7.store.dispatch(
					"removeUnseenMessagesForChat",
					parseInt(messageObj.chat_id)
				);
				f7.emit("scrollToBottom", id);
					refreshPage && f7.views.main.router.refreshPage();
			}, 500);

			// Update chat immediately after message is saved
			try {
				await db.chats.where({ chat_id: messageObj.chat_id }).modify({
					un_read_count: 0,
					message: messageObj.message,
					is_read: 0,
					msg_type: messageObj.type,
					firstname: messageObj.sender_name,
					message_sender_id: loginResponse.data.id,
					unix_time: messageObj.unix_time,
				});
			} catch (ex) {
				console.error("Failed to update chat:", ex);
			}

			return id;
		} catch (ex) {
			console.error("Failed to save message:", ex);
			f7.toast.create({ text: "Failed to update DB" }).open();
			throw ex;
		}
	};

	useEffect(() => {
		f7?.on(
			"sendhi",
			createCancelableFunction((chat_id) => {
				if (chat_id === id) {
					setRefreshPage(true);
					sendMessage("Hi");
				}
			})
		);

		return () => {
			f7.off("sendhi");
			setRefreshPage(false);
		};
	}, [attachments, captions, edit, reply, showMap, id]);

	useEffect(() => {
		if (!reply) return;
		// db.members.where({ chat_id: id }).toArray((members) => {
		// 	if (reply.sender_id === loginResponse.data.id)
		// 		reply.member = { firstname: "You", lastname: "" };
		// 	else
		// 		reply.member = members.find(
		// 			(member) => member.id === reply.sender_id
		// 		);
		// 	console.log("reply update", reply);
		// });
		messageinput.current && messageinput.current.focus();
	}, [reply]);

	useEffect(() => {
		if (!edit) return;
		edit.type === "text"
			? setMessageText(edit.message)
			: setMessageText(edit.caption);

		setTimeout(() => {
			calculateHeight();
			messageinput.current && messageinput.current.focus();
		}, 100);
	}, [edit]);

	useUpdateEffect(() => {
		messageText.trim().length === 0 &&
			messageinput.current.removeAttribute("style");
	}, [messageText]);

	const metaUrl = useMemo(() => {
		if (attachments.length > 0 || metaHidden) return null;

		const data = messageText.trim().match(/(https?:\/\/\S+)/gm);
		if (data) {
			return data[0];
		} else return null;
	}, [messageText]);

	useUpdateEffect(() => {
		if (metaUrl) {
			const hasMeta = metaDatas.find((meta) => meta.url === metaUrl);
			if (!hasMeta) {
				dispatch(fetchMeta({ url: metaUrl }));
			} else {
				!metaHidden && setMetaAvailable(hasMeta);
				!metaHidden && document.body.classList.add("expanded-messagebar");
			}
		} else setMetaAvailable(null);
	}, [metaUrl]);

	useUpdateEffect(() => {
		if (metaUrl && metaResponse && !metaAvailable) {
			const hasMeta = metaDatas.find((meta) => meta.url === metaUrl);
			hasMeta && !metaHidden && setMetaAvailable(hasMeta);
			hasMeta &&
				!metaHidden &&
				document.body.classList.add("expanded-messagebar");
		}
	}, [metaResponse, metaDatas, metaUrl, metaAvailable]);

	const attachFileHandler = (type = "image") => {
		const fileInput = createOpenDialog(type, "file", true);
		fileInput.onchange = (_) => {
			const files = Array.from(fileInput.files);
			files.forEach(async (file, index) => {
				isDuplicateFile(file, attachments).then((duplicate) => {
					if (duplicate)
						f7.toast.create({ text: "File already exists!" }).open();
					else {
						setAttachments((prevAttachment) => [...prevAttachment, file]);
						setCaptions((prevCaption) => [
							...prevCaption,
							!prevCaption?.length && messageText ? messageText : "",
						]);
					}
				});
			});
			fileInput.remove();
		};
		fileInput.click();
	};

	const handleFileDrop = useCallback((item) => {
		if (item) {
			const files = item.files;
			setAttachments((prevAttachments) => [
				...prevAttachments,
				...Array.from(files).map((file) => file),
			]);
			setCaptions((prevCaptions) => [
				...prevCaptions,
				...Array.from(files).map((file, index) =>
					index === 0 ? (messageText ? messageText : "") : ""
				),
			]);
			setTimeout(() => {
				calculateHeight();
			}, 100);
		}
	}, []);

	useLayoutEffect(() => {
		messageText.trim().length === 0 &&
			messageinput.current?.removeAttribute("style");
	}, [messageText]);

	const calculateContentHeight = (ta, scanAmount) => {
		if (!ta) return;
		var origHeight = ta.style.height,
			height = ta.offsetHeight,
			scrollHeight = ta.scrollHeight,
			overflow = ta.style.overflow;

		if (height >= scrollHeight) {
			ta.style.height = height + scanAmount + "px";
			ta.style.overflow = "hidden";
			if (scrollHeight < ta.scrollHeight) {
				while (ta.offsetHeight >= ta.scrollHeight) {
					ta.style.height = (height -= scanAmount) + "px";
				}
				while (ta.offsetHeight < ta.scrollHeight) {
					ta.style.height = height++ + "px";
				}
				ta.style.height = origHeight;
				ta.style.overflow = overflow;
				return height;
			}
		} else {
			return scrollHeight;
		}
	};

	const prevLineCount = useRef(0);

	const calculateHeight = () => {
		if (!messageinput.current) return;
		var ta = messageinput.current,
			style =
				window.getComputedStyle && messageinput.current
					? window.getComputedStyle(ta)
					: ta?.currentStyle,
			taLineHeight = parseInt(style?.lineHeight, 10),
			taHeight = calculateContentHeight(ta, taLineHeight),
			numberOfLines = Math.ceil(taHeight / taLineHeight) - 1;

		if (numberOfLines > 1) {
			messageinput.current.style.height = `${numberOfLines * 22 + 20}px`;
		} else {
			messageinput.current?.removeAttribute("style");
		}

		setInputExtended(messagebarRef.current.clientHeight + "px");

		setTimeout(() => {
			if (prevLineCount.current !== numberOfLines)
				messageinput.current.scrollTop = messageinput.current?.scrollHeight;
			prevLineCount.current = numberOfLines;
		}, 50);
	};

	const handleCameraCapture = useCallback(() => {
		if (cameraMode === "photo") {
			const imageSrc = cameraRef.current.getScreenshot();
			f7.popup.close();
			setCompress(true);
			setAttachments((prevAttachments) => [
				...prevAttachments,
				base64ToFile(imageSrc, "image.png", false),
			]);
			setCaptions((prevCaptions) => [
				...prevCaptions,
				messageText ? messageText : "",
			]);
		} else {
			if (cameraRecording) {
				mediaRecorderRef.current.stop();
				setCameraRecording(false);
				setCameraMode("photo");
				f7.popup.close();
			} else {
				setCameraRecording(true);
				mediaRecorderRef.current = new MediaRecorder(cameraRef.current.stream, {
					mimeType: "video/webm;codecs=H264",
				});
				mediaRecorderRef.current.addEventListener(
					"dataavailable",
					handleVideoDataAvailable
				);
				setVideoDuration(Date.now());
				mediaRecorderRef.current.start();
			}
		}
	}, [cameraMode, cameraRef, cameraRecording, recordedChunks]);

	const handleVideoDataAvailable = useCallback(
		({ data }) => {
			if (data.size > 0) {
				setRecordedChunks((prev) => [...prev, data]);
			}
		},
		[setRecordedChunks]
	);

	useEffect(() => {
		if (recordedChunks.length > 0) {
			const blob = new Blob(recordedChunks, {
				type: "video/webm",
			});
			setMimeCheck(false);
			setCompress(true);
			const duration = Date.now() - videoDuration;
			setVideoDuration(duration);
			setAttachments((prevAttachments) => [
				...prevAttachments,
				blobToFile(blob, "video.webm"),
			]);
			setCaptions((prevCaptions) => [
				...prevCaptions,
				messageText ? messageText : "",
			]);
		}
	}, [recordedChunks]);
	return (
		<>
			{id !== "posh_ai" && (
				<DndProvider backend={MultiBackend} options={HTML5toTouch}>
					<DndContainer onDrop={handleFileDrop} setCompress={setCompress} />
				</DndProvider>
			)}
			<div
				ref={messagebarRef}
				className="toolbar messagebar md:pl-[12px] pl-[6px] md:pr-[24px] pr-[6px] py-[15px] md:gap-[20px]">
				{popupsShouldRender() && (
					<Sheet
						bottom
						backdrop={false}
						closeByOutsideClick={false}
						closeOnEscape
						containerEl=".messagebar"
						className="messagebar-sheet relative"
						opened={sheetOpened}
						onSheetClosed={() => {
							setSheetOpened(false);
						}}>
						<EmojiPicker
							lazyLoadEmojis
							height="-webkit-fill-available"
							width="auto"
							onEmojiClick={(emojiObject) => {
								setMessageText((prevMessageText) => {
									return prevMessageText + emojiObject.emoji;
								});
							}}
						/>
					</Sheet>
				)}
				<div className="toolbar-inner">
					<div className="messagebar-area my-0 rounded-[10px] md:rounded-none">
						{metaAvailable && (
							<div className="flex align-center justify-between rounded-l-[10px]">
								{metaAvailable.image && (
									<div className="h-[95px] w-[113px] pl-[18px] mb-[8px] bg-[#F4F4F4] highlight rounded-l-[10px] flex items-center justify-center shrink-0">
										<LazyLoaderWraper
											src={metaAvailable.image}
											placeholder={blank_placeholder}
											height={85}
											width={85}
											className="rounded-[5px] object-cover"
										/>
									</div>
								)}
								<div className="h-[95px] relative w-full rounded-r-[10px] px-[18px] flex flex-col items-start justify-center bg-[#F4F4F4] mb-[8px] truncate ...">
									<span className="text-primary font-semibold truncate ...">
										{metaAvailable.title}
									</span>
									<span className="text-body text-sm w-full truncate ...">
										{metaAvailable.description}
									</span>
								</div>
							</div>
						)}
						{metaLoading && (
							<div className="flex align-center justify-between rounded-l-[10px]">
								<div className="h-[95px] pl-[18px] mb-[8px] bg-[#F4F4F4] highlight rounded-l-[10px] flex items-center justify-center">
									<SkeletonBlock
										width="75px"
										height="75px"
										effect="wave"
										borderRadius="5px"
									/>
								</div>
								<div className="h-[95px] relative w-full rounded-r-[10px] px-[18px] flex flex-col items-start justify-center bg-[#F4F4F4] mb-[8px] gap-2.5">
									<SkeletonBlock width="275px" effect="wave" />
									<SkeletonBlock width="475px" effect="wave" />
								</div>
							</div>
						)}
						{(reply || edit) && (
							<div className="flex align-center justify-between">
								<div
									className={`${
										reply?.type !== "text" && edit?.type !== "text"
											? "h-[95px]"
											: "h-[55px]"
									} relative w-full rounded-l-[10px] ${
										reply?.type === "text" || edit?.type === "text"
											? "rounded-r-[10px]"
											: null
									} highlight px-[18px] flex flex-col items-start justify-center bg-[#F4F4F4] mb-[8px] truncate ...`}>
									{reply && (
										<span className="text-primary font-semibold truncate ...">{`${
											reply.nickname || reply.sender_name
										}`}</span>
									)}
									<span className="text-body text-sm w-full text-ellipsis overflow-hidden whitespace-nowrap truncate ...">
										{reply ? (
											reply.type === "text" ? (
												<MarkdownPreview
													source={removeMD(
														reply.message.replace(
															/@\[([^\]]+)\]\((\d+)\)/g,
															"@$1"
														)
													)}
													rehypePlugins={[rehypeSanitize]}
												/>
											) : (
												<>
													{" "}
													{reply.type === "contact" && (
														<Profile
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{reply.type === "audio" && (
														<Microphone2
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{reply.type === "location" && (
														<Location
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{reply.type === "mms" && (
														<Sms
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{reply.type !== "contact" &&
														reply.type !== "audio" &&
														reply.type !== "location" &&
														reply.type !== "mms" && (
															<DocumentText
																size="15"
																color="#5D6980"
																variant="Bold"
																className="inline-block align-text-top mr-[2px] mt-[2px]"
															/>
														)}
													{""}
													<MarkdownPreview
														source={
															reply.caption
																? removeMD(
																		truncateText(
																			reply.caption?.replace(
																				/@\[([^\]]+)\]\((\d+)\)/g,
																				"@$1"
																			)
																		)
																  )
																: reply.type === "location"
																? `Location`
																: reply.type === "contact"
																? removeMD(
																		truncateText(
																			JSON.parse(reply.message).contactName
																		)
																  ) ||
																  removeMD(
																		truncateText(
																			JSON.parse(reply.message)
																				.contactTypeInfoList[0].phoneNumber
																		)
																  )
																: reply.type === "mms"
																? JSON.parse(reply.message).message.trim()
																: removeMD(
																		truncateText(
																			reply.message?.replace(
																				/@([^()\s]+)(\([^()]+\))?/g,
																				"@$1"
																			)
																		)
																  )
														}
														rehypePlugins={[rehypeSanitize]}
													/>
												</>
											)
										) : edit ? (
											edit.type === "text" ? (
												<MarkdownPreview
													source={removeMD(
														edit.message.replace(
															/@\[([^\]]+)\]\((\d+)\)/g,
															"@$1"
														)
													)}
													rehypePlugins={[rehypeSanitize]}
												/>
											) : (
												<>
													{edit.type === "contact" && (
														<Profile
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{edit.type === "audio" && (
														<Microphone2
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{edit.type === "location" && (
														<Location
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
													{edit.type !== "contact" &&
														edit.type !== "audio" &&
														edit.type !== "location" && (
															<DocumentText
																size="15"
																color="#5D6980"
																variant="Bold"
																className="inline-block align-text-top mr-[2px] mt-[2px]"
															/>
														)}
													{""}
													<MarkdownPreview
														source={
															edit.caption
																? removeMD(
																		truncateText(
																			edit.caption?.replace(
																				/@\[([^\]]+)\]\((\d+)\)/g,
																				"@$1"
																			)
																		)
																  )
																: edit.type === "contact"
																? removeMD(
																		truncateText(
																			JSON.parse(edit.message).contactName
																		)
																  ) ||
																  removeMD(
																		truncateText(
																			JSON.parse(edit.message)
																				.contactTypeInfoList[0].phoneNumber
																		)
																  )
																: removeMD(
																		truncateText(
																			edit.message?.replace(
																				/@\[([^\]]+)\]\((\d+)\)/g,
																				"@$1"
																			)
																		)
																  )
														}
														rehypePlugins={[rehypeSanitize]}
													/>
												</>
											)
										) : (
											""
										)}
									</span>
								</div>
								{reply && reply?.type !== "text" && reply?.type !== "mms" && (
									<div
										className={`w-[88px] h-[95px] rounded-r-[10px] bg-[#f4f4f4] ${
											reply?.type === "contact"
												? "flex flex-col align-center justify-center"
												: ""
										}`}>
										{reply?.type !== "contact" &&
										reply?.type !== "audio" &&
										reply?.type !== "location" ? (
											<LightboxThumbnail
												item={reply}
												className={`${
													reply?.type !== "image" &&
													reply?.type !== "video" &&
													reply?.type !== "mms"
														? "scale-[2.5] mt-[32px]"
														: "w-[88px] h-[95px] rounded-r-[10px]"
												}`}
											/>
										) : reply?.type === "contact" ? (
											<img
												src={userimg}
												alt="contact"
												className="w-[72px] h-auto rounded-full"
											/>
										) : reply?.type === "audio" ? (
											<i className="kt-ft record scale-[2.5] mt-[32px] mr-[8px]" />
										) : reply?.type === "location" ? (
											<MapContainer
												zoomControl={false}
												dragging={false}
												keyboard={false}
												scrollWheelZoom={false}
												center={reply.message
													.split("...")
													.map((coord) => parseFloat(coord.trim()))}
												zoom={20}
												placeholder={
													<SkeletonBlock effect="wave"></SkeletonBlock>
												}>
												<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
												<Marker
													position={reply.message
														.split("...")
														.map((coord) => parseFloat(coord.trim()))}></Marker>
											</MapContainer>
										) : null}
									</div>
								)}
								{edit && edit?.type !== "text" && (
									<div
										className={`w-[88px] h-[95px] rounded-r-[10px] bg-[#f4f4f4] ${
											edit?.type === "contact"
												? "flex flex-col align-center justify-center"
												: ""
										}`}>
										{edit?.type !== "contact" && edit?.type !== "audio" ? (
											<LightboxThumbnail
												item={edit}
												className={`${
													edit?.type !== "image" && edit?.type !== "video"
														? "scale-[2.5] mt-[32px]"
														: "w-[88px] h-[95px] rounded-r-[10px]"
												}`}
											/>
										) : edit?.type === "contact" ? (
											<img
												src={userimg}
												alt="contact"
												className="w-[72px] h-auto rounded-full"
											/>
										) : edit?.type === "audio" ? (
											<i className="kt-ft record scale-[2.5] mt-[32px] mr-[8px]" />
										) : null}
									</div>
								)}
							</div>
						)}
						<Button
							className="absolute message-area-btn bottom-0 left-[10px] md:left-0 z-[1]"
							onClick={() =>
								setSheetOpened((prevSheetOpened) => !prevSheetOpened)
							}>
							<MaterialIcon
								icon="sentiment_satisfied"
								size={24}
								className="text-[28px]"
							/>
						</Button>
						<MentionsInput
							inputRef={messageinput}
							id="textInput"
							className={`custom-mentionbox resizable`}
							placeholder={placeholder()}
							onChange={handleTextChange}
							onKeyDown={handleKeyDown}
							value={
								attachments.length > 0 ? captions[activeIndex] : messageText
							}
							customSuggestionsContainer={(children) => (
								<div className="p-3 w-[350px] mentions-container">
									{children}
								</div>
							)}
							allowSuggestionsAboveCursor={true}
							allowSpaceInQuery={true}>
							<Mention
								trigger="@"
								data={members}
								markup="@[__display__](__id__)"
								className="mentions__mention"
								renderSuggestion={(
									entry,
									search,
									highlightedDisplay,
									index,
									focused
								) => (
									<div
										className={`${
											focused ? "bg-[#F4F4F4] text-primary" : "bg-transparent"
										} p-2 flex items-center justify-start rounded-md`}>
										<LazyLoaderWraper
											src={entry.avater}
											placeholder={userimg}
											height={39}
											width={39}
											alt=""
											className="rounded-full object-cover"
										/>
										<span className="text-body ml-2">{highlightedDisplay}</span>
									</div>
								)}
								appendSpaceOnAdd
							/>
						</MentionsInput>
						{!attachmentsVisible() && id !== "posh_ai" && (
							<Button
								popoverOpen=".attachment-menu"
								className="absolute message-area-btn right-0 bottom-0 rotate-45">
								<MaterialIcon
									icon="attach_file"
									size={24}
									className="text-[28px]"
								/>
							</Button>
						)}
						{/* {id === "posh_ai" && (
							<div className="absolute message-area-btn w-fit px-2.5 right-0 bottom-0 flex items-center justify-between gap-[5px]">
								{t("Web Search")}
								<Toggle />
							</div>
						)} */}
					</div>
					<div className="pl-1 md:pl-0">
						{metaLoading && (
							<div
								className={`${
									reply?.type !== "text" && edit?.type !== "text"
										? "h-[95px]"
										: "h-[55px]"
								} flex flex-col items-center justify-center mb-[8px] opacity-0`}>
								<Button className="h-[40px] pointer-events-none">
									<MaterialIcon
										icon="close"
										size={24}
										weight={400}
										color="#5D6980"
									/>
								</Button>
							</div>
						)}
						{(reply || edit || metaAvailable) && (
							<div
								className={`${
									reply?.type !== "text" && edit?.type !== "text"
										? "h-[95px]"
										: "h-[55px]"
								} flex flex-col items-center justify-center mb-[8px]`}>
								<Button
									className="h-[40px]"
									onClick={() => {
										reply && f7.store.dispatch("setReply", null);
										if (edit) {
											f7.store.dispatch("setEdit", null);
											setMessageText("");
											draftCache.current = null;
										}
										if (metaAvailable) {
											setMetaAvailable(null);
											setMetaHidden(true);
										}

										document.body.classList.remove("expanded-messagebar");
										messageinput.current && messageinput.current.focus();
									}}>
									<MaterialIcon
										icon="close"
										size={24}
										weight={400}
										color="#5D6980"
									/>
								</Button>
							</div>
						)}
						{showMicButton() ? (
							<Button
								className="rounded-full w-[48px] h-[48px]"
								onClick={toggleAudioInput}>
								<MaterialIcon
									fill
									icon="mic"
									size={24}
									className="text-[32px] color-secondary"
								/>
							</Button>
						) : (
							<Button
								fill
								className="rounded-full w-[48px] h-[48px]"
								disabled={id === "posh_ai" && messageText.trim().length === 0}
								onClick={(e) => sendMessage()}>
								<Send size="20" color="#FFFFFF" variant="Bold" />
							</Button>
						)}
					</div>
				</div>
				{attachmentsVisible() && (
					<Popup
						tabletFullscreen
						className="attachment-popup"
						backdrop={false}
						closeByBackdropClick={false}
						onPopupOpened={() => {
							messageinput.current && messageinput.current.focus();
							document.body.classList.add("with-attachment-popup");
						}}
						onPopupClose={() => {
							messageinput.current && messageinput.current.focus();
							document.body.classList.remove("with-attachment-popup");
							messageinput.current && messageinput.current.focus();
						}}
						containerEl=".messagebar"
						opened={attachmentsOpen}
						style={{
							top: `calc(-100vh + var(--f7-navbar-height) + ${
								inputExtended
									? inputExtended
									: messagebarRef.current?.clientHeight + "px"
							})`,
							height: `calc(100vh - ${
								inputExtended
									? inputExtended
									: messagebarRef.current?.clientHeight + "px"
							} - var(--f7-navbar-height))`,
						}}>
						<Page className="bg-white/100 backdrop-blur-[50px]">
							<Button
								popupClose
								className="absolute h-[50px] p-0 top-0"
								onClick={() => {
									setAttachments([]);
									setCaptions([]);
									setCompress(false);
									setAttachmentsOpen(false);
									setTimeout(() => {
										const htmlEl = document.getElementsByTagName("html")[0];
										if (htmlEl.classList.contains("with-modal-popup")) {
											htmlEl.classList.remove("with-modal-popup");
										}
									}, 100);
									setActiveDocument({});
									setActiveIndex(0);
									document.body.classList.remove("with-attachment-popup");
								}}>
								<MaterialIcon
									icon="close"
									grad={-25}
									weight={300}
									className="text-secondary text-[36px]"
								/>
							</Button>
							<div className="flex flex-col items-center justify-between flex-[1_0%_0%] self-stretch h-full">
								{getFileTypeByName(activeDocument?.fileName) === "image" ? (
									<div className="flex items-center justify-center gap-2.5">
										{/* <Button className="h-[50px] w-[50px]">
											<Crop color="#5D6980" size={32} />
										</Button>
										<Button className="h-[50px] w-[50px]">
											<Sticker color="#5D6980" size={32} variant="Outline" />
										</Button>
										<Button className="h-[50px] w-[50px]">
											<Text color="#5D6980" size={32} />
										</Button>
										<Button className="h-[50px] w-[50px]">
											<MaterialIcon
												icon="gesture"
												className="text-secondary text-[36px]"
												weight={300}
												grad={-25}
											/>
										</Button> */}
									</div>
								) : (
									<div className="flex flex-col items-center justify-center gap-2.5 w-full">
										<span className="text-body text-align text-ellipsis text-2xl whitespace-nowrap overflow-hidden max-w-[70%] leading-[50px]">
											{activeDocument?.fileName}
										</span>
									</div>
								)}
								<div
									className={`file-container max-h-[80%] ${
										getFileTypeByName(activeDocument.fileName) !== "pdf"
											? "self-stretch"
											: "overflow-hidden"
									} overflow-auto ${
										(getFileTypeByName(activeDocument.fileName) === "image" ||
											getFileTypeByName(activeDocument.fileName) === "video") &&
										"contents"
									}`}>
									{getFileTypeByName(activeDocument.fileName) === "image" && (
										<img
											key={activeDocument.fileName}
											src={activeDocument?.uri}
											className="max-h-[80%]"
										/>
									)}
									{getFileTypeByName(activeDocument.fileName) === "video" && (
										<video
											key={activeDocument.fileName}
											controls
											className="max-h-[80%]">
											<source src={activeDocument.uri} type="video/mp4" />
										</video>
									)}
									{getFileTypeByName(activeDocument.fileName) === "pdf" && (
										<PdfViewer
											key={activeDocument.fileName}
											file={attachments[activeIndex]}
										/>
									)}
									{getFileTypeByName(activeDocument.fileName) !== "image" &&
										getFileTypeByName(activeDocument.fileName) !== "video" &&
										getFileTypeByName(activeDocument.fileName) !== "pdf" && (
											<div
												key={activeDocument.fileName}
												className="flex flex-col items-center justify-center">
												{attachmentsVisible() && (
													<div className="h-[115px]">
														<LightboxThumbnail
															item={{
																file: attachments[activeIndex],
															}}
															className="origin-top scale-[2.5]"
														/>
													</div>
												)}
												<h1 className="font-bold text-body text-lg">
													No Preview Available
												</h1>
												{attachmentsVisible() && (
													<h1 className="text-body">
														{activeDocument.fileName},{" "}
														{formatBytes(attachments[activeIndex]?.size)}{" "}
													</h1>
												)}
											</div>
										)}
								</div>
								<MessagebarAttachments className="px-0 py-[6px] block m-0">
									<div className="my-0 mx-auto w-fit">
										{attachments.map((item, index) => (
											<MessagebarAttachment
												key={index}
												onAttachmentDelete={() => deleteAttachment(index)}
												className={`shrink-0 w-[62px] h-[62px] rounded-[5px] attachment-thumbnail-button ${
													activeIndex === index && "active"
												}`}>
												<div
													className="w-full h-full flex items-center justify-center"
													onClick={() => {
														setActiveDocument({
															uri: window.URL.createObjectURL(item),
															fileName: item.name,
														});
														setActiveIndex(index);
													}}>
													<LightboxThumbnail item={{ file: item }} />
													{activeIndex === index && (
														<span className="overlay"></span>
													)}
												</div>
											</MessagebarAttachment>
										))}
										<MessagebarAttachment className="shrink-0 w-[62px] h-[62px] rounded-[5px] attachment-thumbnail-button attachment-add-button cursor-pointer">
											<div
												className="w-full h-full flex items-center justify-center "
												onClick={() => {
													compress
														? attachFileHandler(["image", "video"])
														: attachFileHandler("*");
												}}>
												<Add
													color="#B9BCBE"
													size={40}
													className="pointer-events-none"
												/>
											</div>
										</MessagebarAttachment>
									</div>
								</MessagebarAttachments>
							</div>
						</Page>
					</Popup>
				)}

				{/* locaton popup started here */}
				{popupsShouldRender() && (
					<Popup
						tabletFullscreen
						className="map-popup"
						backdrop={false}
						closeByBackdropClick={false}
						closeOnEscape
						onPopupOpened={() => {
							setTimeout(() => setShowMap(true), 150);
							document.body.classList.add("with-attachment-popup");
							messageinput.current && messageinput.current.focus();
						}}
						onPopupClose={() => {
							setShowMap(false);
							setLocationPopupOpened(false);
							document.body.classList.remove("with-attachment-popup");
							messageinput.current && messageinput.current.focus();
						}}
						containerEl=".messagebar"
						opened={locationPopupOpened}
						style={{
							top: `calc(-100vh + var(--f7-navbar-height) + ${
								inputExtended
									? inputExtended
									: messagebarRef.current?.clientHeight + "px"
							})`,
							height: `calc(100vh - ${
								inputExtended
									? inputExtended
									: messagebarRef.current?.clientHeight + "px"
							} - var(--f7-navbar-height))`,
						}}>
						<Page className="bg-white/100 backdrop-blur-[50px]">
							<Button
								popupClose
								className="absolute h-[50px] pr-[0px] z-[1111]">
								<MaterialIcon
									icon="close"
									grad={-25}
									weight={300}
									className="text-secondary text-[36px]"
								/>
							</Button>
							{/* Map Container */}

							{showMap && <MapWithLocation setMapPosition={setMapPosition} />}
							{/* <div className="pt-[10px] pl-[25px] flex">
								<Button className="h-[50px] w-[50px]">
									<MaterialIcon
										icon="share_location"
										className="text-[var(--f7-md-primary-shade)] text-[36px]"
										weight={300}
										grad={-25}
									/>
								</Button>
								<span className="pt-[12px]">{t("Share live location")}</span>
							</div> */}
							{/* <div className="pt-[10px] pl-[25px]">
							<span>Nearby places</span>
						</div>
						<div
							className="pt-[10px] pl-[25px] flex"
							onClick={() => {
								sendMessage();
							}}>
							<Button className="h-[50px] w-[50px]">
								<MaterialIcon
									icon="radio_button_checked"
									className="--f7-md-primary-shade text-[36px]"
									weight={300}
									grad={-25}
								/>
							</Button>
							<span className="pt-[12px]">Send your current location</span>
						</div>
						<div className="font-[Nunito] text-[14px] font-normal leading-[19.1px] pl-[72px]">
							<p>Accurate to 12.917 meters</p>
						</div>
						<div className="pt-[10px] pl-[25px] flex">
							<Button className="h-[50px] w-[50px]">
								<MaterialIcon
									icon="location_on"
									className="--f7-md-primary-shade text-[36px]"
									weight={300}
									grad={-25}
								/>
							</Button>
							<span className="pt-[12px]">Ogoul Technology Co. W.L.L.</span>
						</div>
						<div className="font-[Nunito] text-[14px] font-normal leading-[19.1px] pl-[72px]">
							<p>Accurate to 12.917 meters</p>
						</div> */}
						</Page>
					</Popup>
				)}
				{popupsShouldRender() && (
					<Popup
						opened={cameraOpened}
						className="camera-popup"
						onPopupClose={() => setCameraOpened(false)}>
						<Page>
							<Navbar>
								<NavTitle className="text-2xl font-medium">
									{cameraMode === "photo" ? t("Take Photo") : t("Take Video")}
								</NavTitle>
								<NavRight>
									<Link popupClose>
										<MaterialIcon size={24} icon="close" />
									</Link>
								</NavRight>
							</Navbar>
							<div className="flex flex-col h-full w-full items-center justify-stretch">
								{cameraOpened && (
									<Webcam
										mirrored
										ref={cameraRef}
										audio
										screenshotFormat="image/png"
										onUserMediaError={(error) => {
											if (error.name === "NotAllowedError") {
												setCameraOpened(false);
												f7.dialog.confirm(
													t(
														"Camera permission is denied by the user. Do you want to learn how to enable it?"
													),
													t("Camera Permission"),
													() => {
														window.open(
															"https://docs.buddypunch.com/en/articles/919274-how-do-i-enable-the-webcam-for-specific-browsers",
															"_system"
														);
													}
												);
											}
										}}
										videoConstraints={{
											...(desktop && {
												...(supportedContraints["width"] && {
													width: 750,
												}),
												...(supportedContraints["height"] && {
													height: 576,
												}),
											}),
											facingMode: "user",
										}}
									/>
								)}
								<div className="relative flex items-center justify-center h-[100px]">
									<Button
										round
										active
										className="absolute w-[65px] h-[65px] md:w-[90px] md:h-[90px] p-2.5 -top-[25px] md:-top-[50px] z-10"
										onClick={handleCameraCapture}>
										{cameraMode === "photo" ? (
											<Camera
												size={desktop ? 48 : 32}
												color="#FFFFFF"
												variant="Bold"
											/>
										) : !cameraRecording ? (
											<RecordCircle
												size={desktop ? 48 : 32}
												color="#FFFFFF"
												variant="Bold"
											/>
										) : (
											<StopCircle
												size={desktop ? 48 : 32}
												color="#FFFFFF"
												variant="Bold"
											/>
										)}
									</Button>
									<Segmented round>
										<Button
											small
											round
											outline
											className="w-[100px] h-[35px] md:w-[150px] md:h-[43px] px-2.5 py-[5px] font-medium text-lg md:text-2xl leading-[32px]"
											active={cameraMode === "photo"}
											onClick={() => setCameraMode("photo")}>
											{t("Photo")}
										</Button>
										<Button
											small
											round
											outline
											className="w-[100px] h-[35px] md:w-[150px] md:h-[43px] px-2.5 py-[5px] font-medium text-lg md:text-2xl leading-[32px]"
											active={cameraMode === "video"}
											onClick={() => setCameraMode("video")}>
											{t("Video")}
										</Button>
									</Segmented>
								</div>
							</div>
						</Page>
					</Popup>
				)}
				<Popover
					arrow={false}
					backdrop={false}
					className="attachment-menu w-[200px]">
					<List>
						<ListItem
							title={t("Camera")}
							className="text-black cursor-pointer"
							onClick={() => {
								setCameraOpened(true);
								f7.popover.close();
							}}>
							<Camera slot="media" color="#0e99fe" variant="Bold" />
						</ListItem>
						<ListItem
							title={t("Gallery")}
							className="text-black cursor-pointer"
							popoverClose
							onClick={() => {
								f7.popover.close();
								setCompress(true);
								attachFileHandler(["image", "video"]);
							}}>
							<Gallery slot="media" color="#0e99fe" variant="Bold" />
						</ListItem>
						<ListItem
							title={t("Document")}
							className="text-black cursor-pointer"
							popoverClose
							onClick={() => {
								f7.popover.close();
								setCompress(false);
								attachFileHandler("*");
							}}>
							<DocumentText slot="media" color="#0e99fe" variant="Bold" />
						</ListItem>
						<ListItem
							title={t("Location")}
							className="text-black cursor-pointer"
							popoverClose
							onClick={() => {
								f7.popover.close();
								setLocationPopupOpened(!locationPopupOpened);
							}}>
							<Location slot="media" color="#0e99fe" variant="Bold" />
						</ListItem>
						{/* <ListItem
							title={t("Contact")}
							className="text-black cursor-pointer"
							popoverClose
							onClick={() => {
								f7.popover.close();
							}}>
							<UserSquare slot="media" color="#0e99fe" variant="Bold" />
						</ListItem> */}
					</List>
				</Popover>
			</div>
		</>
	);
};

export default Messagebar;
